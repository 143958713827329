import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import CancelIcon from "@mui/icons-material/Cancel";
import VerifiedIcon from "@mui/icons-material/Verified";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { useSelector } from "react-redux";
import "./NewMatches.css";
import axios from "axios";
import { message } from "antd";
import IMAGES from "../img/image";
import FilterForm from "./components/FilterForm";
import Pagination from "./components/Pagination";
import { useNavigate } from "react-router-dom";
import SearchForm from "./components/SearchForm";

const AdvanceSearch = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [allUser, setAllUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    fromAge: "",
    toAge: "",
    maritalStatus: "",
    qualification: "",
    salary: "",
    state: "",
    city: "",
  });

  // Filter function
  const applyFilter = (user) => {
    const {
      fromAge,
      toAge,
      maritalStatus,
      qualification,
      salary,
      state,
      city,
    } = filter;

    return (
      (fromAge === "" || user.age >= fromAge) &&
      (toAge === "" || user.age <= toAge) &&
      (maritalStatus === "" || user.maritalStatus === maritalStatus) &&
      (qualification === "" || user.qualification === qualification) &&
      (salary === "" || user.salary === salary) &&
      (state === "" || user.state === state) &&
      (city === "" || user.city === city)
    );
  };

  const isFilterActive =
    filter.fromAge ||
    filter.toAge ||
    filter.maritalStatus ||
    filter.qualification ||
    filter.salary ||
    filter.state ||
    filter.city;

  const filteredUsers = allUser?.filter(applyFilter);

  const getAllUser = async (gender) => {
    try {
      setLoading(true);
      const res = await axios.post("/api/user/get-all-users", {
        gender: gender === "Male" ? "Female" : "Male",
        country: user?.country,
      });
      if (res.data.success) {
        setAllUser(res.data.data);
      } else {
        message.error(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        getAllUser(res.data.data.gender);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const totalItems = filteredUsers?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const paginatedUsers = filteredUsers?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Layout>
      <div className="new-matches-container container">
        <img src={IMAGES.noResult} alt="" />
        <h4 className="text-success">COMING SOON</h4>
      </div>
    </Layout>
  );
};

export default AdvanceSearch;
