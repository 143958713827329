import React, { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const AboutYourself = ({ handleChange, activeUser, setActiveUser }) => {
  const [field, setField] = useState(true);
  return (
    <div className="basic-info mt-3">
      <div className="title">
        <div className="d-flex justify-content-between">
          <span>More About, Yourself, Partner and Family</span>
          <span>
            {field ? (
              <RemoveIcon onClick={() => setField(false)} />
            ) : (
              <AddIcon onClick={() => setField(true)} />
            )}
          </span>
        </div>
        <hr className="m-0 p-0 mt-2 mb-3" />
        <div className={`row fields-container ${field ? "" : "off"}`}>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                <small>
                  Personality, Family Details, Career, Partner Expectations etc
                </small>
              </label>
              <textarea
                onChange={(e) => handleChange("about", e.target.value)}
                value={activeUser?.about}
                className="form-control"
                name="about"
                cols="10"
                rows="5"
              ></textarea>
            </div>
          </div>
          {/* <span className="text-center m-0">
            <small>
              Note: When you update your profile, it goes for verification.
            </small>
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default AboutYourself;
