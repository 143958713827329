import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import { message } from "antd";
import "./adminPlan.css";
import { useNavigate, useParams } from "react-router-dom";

const AdminPlans = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [allPlan, setAllPlan] = useState(null);
  const [form, setForm] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState({
    name: "",
    originalPrice: "",
    price: "",
    contacts: "",
    validity: "",
    discount: "",
    features: [{ name: "" }],
  });

  const handleAddFeature = () => {
    setPlan((prev) => ({
      ...prev,
      features: [...prev.features, { name: "" }],
    }));
  };

  const handleRemoveFeature = (index) => {
    const updatedFeatures = [
      ...plan.features.slice(0, index),
      ...plan.features.slice(index + 1),
    ];
    setPlan((prevPlan) => ({
      ...prevPlan,
      features: updatedFeatures,
    }));
  };

  const handlePlan = async (e) => {
    if (
      plan?.name === "" ||
      plan?.originalPrice === "" ||
      plan?.price === "" ||
      plan?.contacts === "" ||
      plan?.validity === ""
    ) {
      return setError(true);
    }
    e.preventDefault();
    try {
      const res = await axios.post("/api/admin/add-plan", plan, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setForm(!form);
        getAllPlan();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (index !== undefined) {
      const updatedFeatures = plan.features.map((feature, i) =>
        i === index ? { ...feature, [name]: value } : feature
      );
      setPlan((prevPlan) => ({
        ...prevPlan,
        features: updatedFeatures,
      }));
    } else {
      setPlan({ ...plan, [name]: value });
    }
  };

  const getPlanById = async () => {
    try {
      const res = await axios.post(
        "/api/admin/get-plan-by-id",
        {
          id: params.id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setPlan(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletePlan = async (id) => {
    try {
      const res = await axios.post(
        "/api/admin/delete-plan",
        { id: id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setForm(false);
        getAllPlan();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPlanById();
  }, [params]);

  const getAllPlan = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/get-plans");
      if (res.data.success) {
        setAllPlan(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPlan();
  }, []);
  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Premium Plans</h3>
          {!form && (
            <button onClick={() => setForm(!form)}>Add New Plan</button>
          )}
          {form && (
            <button onClick={handlePlan}>
              {params.id ? "Update Plan" : "Add Plan"}
            </button>
          )}
        </div>
        <hr />
        {form && (
          <div className="add-plan container bg-light my-4">
            <div className="row">
              <div className="mb-3 form-fields col-12 col-sm-12 col-md-12 col-lg-12">
                <label className="form-label" htmlFor="">
                  Plan Name
                </label>
                <input
                  name="name"
                  onChange={handleChange}
                  value={plan.name}
                  placeholder="Enter name"
                  className="form-control"
                  type="text"
                />
                <span className="text-danger">
                  <small>
                    {error && plan.name === "" && "Enter plan name"}
                  </small>
                </span>
              </div>
              <div className="mb-3 form-fields col-12 col-sm-12 col-md-12 col-lg-12">
                <label className="form-label" htmlFor="">
                  Original Price
                </label>
                <input
                  name="originalPrice"
                  onChange={handleChange}
                  value={plan.originalPrice}
                  placeholder="Enter price"
                  className="form-control"
                  type="text"
                />
                <span className="text-danger">
                  <small>{error && plan.price === "" && "Enter price"}</small>
                </span>
              </div>
              <div className="mb-3 form-fields col-12 col-sm-12 col-md-12 col-lg-12">
                <label className="form-label" htmlFor="">
                  Plan Price
                </label>
                <input
                  name="price"
                  onChange={handleChange}
                  value={plan.price}
                  placeholder="Enter price"
                  className="form-control"
                  type="text"
                />
                <span className="text-danger">
                  <small>{error && plan.price === "" && "Enter price"}</small>
                </span>
              </div>
              <div className="mb-3 form-fields col-12 col-sm-12 col-md-12 col-lg-12">
                <label className="form-label" htmlFor="">
                  Contacts
                </label>
                <input
                  name="contacts"
                  onChange={handleChange}
                  value={plan.contacts}
                  placeholder="Enter contacts"
                  className="form-control"
                  type="text"
                />
                <span className="text-danger">
                  <small>
                    {error && plan.contacts === "" && "Enter contacts"}
                  </small>
                </span>
              </div>
              <div className="mb-3 form-fields col-12 col-sm-12 col-md-12 col-lg-12">
                <label className="form-label" htmlFor="">
                  Validity
                </label>
                <input
                  name="validity"
                  onChange={handleChange}
                  value={plan.validity}
                  placeholder="Enter plan validity (numbers only)"
                  className="form-control"
                  type="text"
                />
                <span className="text-danger">
                  <small>
                    {error && plan.validity === "" && "Enter plan validity"}
                  </small>
                </span>
              </div>
              <div className="mb-3 form-fields col-12 col-sm-12 col-md-12 col-lg-12">
                <label className="form-label" htmlFor="">
                  Discount
                </label>
                <input
                  name="discount"
                  onChange={handleChange}
                  value={plan.discount}
                  placeholder="Enter discount"
                  className="form-control"
                  type="text"
                />
                <span className="text-danger">
                  <small>
                    {error && plan.validity === "" && "Enter plan validity"}
                  </small>
                </span>
              </div>
              <div className="mb-3 form-fields col-12 col-sm-12 col-md-10 col-lg-10">
                <label className="form-label" htmlFor="">
                  Features
                </label>
                <button
                  onClick={handleAddFeature}
                  className="btn btn-success m-0 ms-3 mb-3"
                >
                  Add
                </button>
                {plan?.features?.map((item, index) => {
                  return (
                    <div className="mb-2 d-flex justify-content-center align-items-center">
                      <input
                        name="features"
                        onChange={(e) => handleChange(e, index)}
                        value={item?.features}
                        placeholder="Feature name"
                        className="form-control"
                        type="text"
                      />
                      <button
                        className="m-0 btn btn-danger"
                        onClick={() => handleRemoveFeature(index)}
                      >
                        Remove
                      </button>
                    </div>
                  );
                })}
                <span className="text-danger">
                  <small>
                    {error &&
                      plan.features.length === 0 &&
                      "Enter plan features"}
                  </small>
                </span>
              </div>
              <button className="register-btn" onClick={handlePlan}>
                {params.id ? "Update Plan" : "Add Plan"}
              </button>
            </div>
          </div>
        )}
        <div className="table-container">
          {loading ? (
            <div className="loading-container">
              <div class="loader">
                <span class="loader-text">loading</span>
                <span class="load"></span>
              </div>
            </div>
          ) : (
            <table className="table user-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Contacts</th>
                  <th>Validity</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allPlan &&
                  allPlan.map((item, index) => {
                    return (
                      <tr>
                        <td>{item?.name}</td>
                        <td>{item?.price}</td>
                        <td>{item?.contacts}</td>
                        <td>{item?.validity} Months</td>
                        <td>
                          <button
                            onClick={() => {
                              setForm(true);
                              navigate(`/admin-plans/${item?._id}`);
                            }}
                            className="btn border"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => handleDeletePlan(item?._id)}
                            className="btn border ms-2"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminPlans;
