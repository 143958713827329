import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { useParams } from "react-router-dom";
import "./BlogPage.css";
import axios from "axios";
import { message } from "antd";

const BlogPage = () => {
  const params = useParams();
  const [blog, setBlog] = useState(null);

  const getBlogByName = async () => {
    try {
      const res = await axios.post("/api/blog/get-blog-by-title", {
        name: params.name,
      });
      if (res.data.success) {
        setBlog(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBlogByName();
  }, []);
  return (
    <Layout>
      <div className="blogpage-container">
        <div className="blogpage-blog">
          <img src={`blogImages/${blog?.image}`} alt="" />
          <h1>{blog && blog.title}</h1>
          <p>{blog && blog.description}</p>
        </div>
      </div>
    </Layout>
  );
};

export default BlogPage;
