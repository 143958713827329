const IMAGES = {
  // Default Image of a user
  male: require("./default/male.png"),
  female: require("./default/female.png"),
  noResult: require("./default/no-result.png"),
  // HOME
  herobg: require("../img/home/herobg.jpg"),
  trusted: require("../img/home/trusted.svg"),
  register: require("./home/register.svg"),
  verified: require("./home/verified.svg"),
  privacy: require("./home/privacy.svg"),
  privacy2: require("./home/privacy.png"),
  verified2: require("./home/verified.png"),
  like: require("./home/like.png"),
  algo: require("./home/algo.png"),
  check: require("./home/check.png"),
  shield: require("./home/shield.png"),
  blog1: require("./home/blog1.png"),
  // contact
  contact: require("../img/contact/1.png"),
  c2: require("../img/contact/2.jpg"),
  c3: require("../img/contact/3.jpg"),
  c4: require("../img/contact/4.jpg"),
  // photos page
  photoview1: require("../img/photoUpload/p1.gif"),
  photoview2: require("../img/photoUpload/p2.gif"),
  photoview3: require("../img/photoUpload/p3.gif"),
  photoview4: require("../img/photoUpload/p4.gif"),
  photoview5: require("../img/photoUpload/p5.gif"),
  photoview6: require("../img/photoUpload/p6.gif"),
  // PDF
  pdf: require("../img/pdf.png"),
  // Offer
  offer: require("../img/offer.png"),
};

export default IMAGES;
