import React, { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const FamilyInfo = ({ handleChange, activeUser, setActiveUser }) => {
  const [field, setField] = useState(true);
  return (
    <div className="basic-info mt-3">
      <div className="title">
        <div className="d-flex justify-content-between">
          <span>Family</span>
          <span>
            {field ? (
              <RemoveIcon onClick={() => setField(false)} />
            ) : (
              <AddIcon onClick={() => setField(true)} />
            )}
          </span>
        </div>
        <hr className="m-0 p-0 mt-2 mb-3" />
        <div className={`row fields-container ${field ? "" : "off"}`}>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Father Name
              </label>
              <input
                type="text"
                onChange={(e) => handleChange("fatherName", e.target.value)}
                value={activeUser?.fatherName}
                name="fatherName"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Father Status
              </label>
              <select
                onChange={(e) => handleChange("fatherStatus", e.target.value)}
                value={activeUser?.fatherStatus}
                name="fatherStatus"
                className="form-select"
              >
                <option value="Select">Select</option>
                <option value="Employed">Employed</option>
                <option value="Business">Business</option>
                <option value="Retired">Retired</option>
                <option value="Not Employed">Not Employed</option>
                <option value="Passed Away">Passed Away</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Mother Status
              </label>
              <select
                value={activeUser?.motherStatus}
                onChange={(e) => handleChange("motherStatus", e.target.value)}
                name="motherStatus"
                className="form-select"
              >
                <option value="Select">Select</option>
                <option value="Homemaker">Homemaker</option>
                <option value="Employed">Employed</option>
                <option value="Business">Business</option>
                <option value="Retired">Retired</option>
                <option value="Passed Away">Passed Away</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                No of Brothers
              </label>
              <input
                className="form-control"
                onChange={(e) => handleChange("brothers", e.target.value)}
                value={activeUser?.brothers}
                name="brothers"
                placeholder="0"
                type="number"
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                No of Sisters
              </label>
              <input
                className="form-control"
                onChange={(e) => handleChange("sisters", e.target.value)}
                value={activeUser?.sisters}
                name="sisters"
                placeholder="0"
                type="number"
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Family Type
              </label>

              <select
                onChange={(e) => handleChange("familyType", e.target.value)}
                value={activeUser?.familyType}
                name="familyType"
                className="form-select"
              >
                <option value="Select">Select</option>
                <option value="Joint">Joint</option>
                <option value="Nuclear">Nuclear</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Native Place
              </label>
              <input
                onChange={(e) => handleChange("nativePlace", e.target.value)}
                value={activeUser?.nativePlace}
                placeholder="Enter native place"
                name="nativePlace"
                type="text"
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FamilyInfo;
