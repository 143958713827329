import React from "react";
import "./Header.css";
import Person2Icon from "@mui/icons-material/Person2";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const LogoutTippy = ({ user }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    message.success("Logout Successful");
    navigate("/login");
  };
  return (
    <div className="logout-tippy">
      <div className="section-1">
        <span>
          <Person2Icon className="me-2 icon" />
        </span>
        <span onClick={() => navigate("/profile")}>Profile</span>
      </div>
      <div className="section-2">
        <span>
          <LogoutIcon className="me-2 icon" />
        </span>
        <span onClick={handleLogout}>Logout</span>
      </div>
      <div className="section-3">
        <small>
          <span>Account Type : {user?.contacts > 0 ? "Premium" : "Free"}</span>
        </small>
        <br />
        {user?.contacts <= 0 && (
          <button onClick={() => navigate("/premium-plans")}>
            <small className="d-block m-auto text-center w-100">Upgrade</small>
          </button>
        )}
      </div>
    </div>
  );
};

export default LogoutTippy;
