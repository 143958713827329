import React, { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Multiselect from "multiselect-react-dropdown";

const PartnerEducationCareer = ({
  handleChange,
  activeUser,
  setActiveUser,
}) => {
  const [field, setField] = useState(true);
  const education = {
    options: [
      { name: "SSC" },
      { name: "HSC" },
      { name: "Under Graduate" },
      { name: "Graduated" },
      { name: "Post Graduation" },
      { name: "Doctorate" },
      { name: "MBA" },
      { name: "Other" },
    ],
  };
  const workingWith = {
    options: [
      { name: "Doesn't Matter" },
      { name: "Private Sector" },
      { name: "Government Sector" },
      { name: "Defense / Civil Services" },
      { name: "Business / Self Employed" },
      { name: "Not Working" },
    ],
  };
  return (
    <div className="basic-info mt-3">
      <div className="title">
        <div className="d-flex justify-content-between">
          <span>Partner Education & Career</span>
          <span>
            {field ? (
              <RemoveIcon onClick={() => setField(false)} />
            ) : (
              <AddIcon onClick={() => setField(true)} />
            )}
          </span>
        </div>
        <hr className="m-0 p-0 mt-2 mb-3" />
        <div className={`row fields-container ${field ? "" : "off"}`}>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Partner Education*
              </label>
              <Multiselect
                id="category"
                className="multiselect-wrapper"
                isObject={false}
                onRemove={(event) => handleChange("partnerEducation", event)}
                onSelect={(event) => handleChange("partnerEducation", event)}
                options={education?.options.map((lang) => lang?.name)}
                selectedValues={activeUser?.partnerEducation?.map(
                  (item) => item
                )}
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Partner working with*
              </label>
              <Multiselect
                id="category"
                className="multiselect-wrapper"
                isObject={false}
                onRemove={(event) => handleChange("partnerWorkingWith", event)}
                onSelect={(event) => handleChange("partnerWorkingWith", event)}
                options={workingWith?.options.map((lang) => lang?.name)}
                selectedValues={activeUser?.partnerWorkingWith?.map(
                  (item) => item
                )}
              />
            </div>
          </div>
          {activeUser?.partnerWorkingWith !== "Not Working" && (
            <>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Partner Employed As*
                  </label>
                  <select
                    onChange={(e) =>
                      handleChange("partnerEmployedAs", e.target.value)
                    }
                    value={activeUser?.partnerEmployedAs}
                    className="form-select"
                    name="partnerEmployedAs"
                  >
                    <option value="Doesn't Matter">Doesn't Matter</option>
                    <option value="Not Working">Not Working</option>
                    <option value="Banking Professional">
                      Banking Professional
                    </option>
                    <option value="Chartered Accountant">
                      Chartered Accountant
                    </option>
                    <option value="Company Secretary">Company Secretary</option>
                    <option value="Finance Professional">
                      Finance Professional
                    </option>
                    <option value="Event Manager">Event Manager</option>
                    <option value="Air Hostess">Air Hostess</option>
                    <option value="Pilot">Pilot</option>
                    <option value="Architect">Architect</option>
                    <option value="Interior Designer">Interior Designer</option>
                    <option value="Web/UX Designer">Web/UX Designer</option>
                    <option value="Artist">Artist</option>
                    <option value="Beautician">Beautician</option>
                    <option value="Fashion Designer">Fashion Designer</option>
                    <option value="Hair Stylist">Hair Stylist</option>
                    <option value="IAS/IRS/IES/IFS">IAS/IRS/IES/IFS</option>
                    <option value="Army/Navy">Army/Navy</option>
                    <option value="Lecturer">Lecturer</option>
                    <option value="Teacher">Teacher</option>
                    <option value="Civil Engineer">Civil Engineer</option>
                    <option value="Software Developer">
                      Software Developer
                    </option>
                    <option value="Doctor">Doctor</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Partner Annual Salary*
                  </label>
                  <select
                    onChange={(e) =>
                      handleChange("partnerSalary", e.target.value)
                    }
                    value={activeUser?.partnerSalary}
                    className="form-select"
                    name="partnerSalary"
                  >
                    <option value="Doesn't Matter">Doesn't Matter</option>
                    <option value="Upto 1 Lakh">Upto 1 Lakh</option>
                    <option value="1 to 2 Lakh">1 to 2 Lakh</option>
                    <option value="2 to 4 Lakh">2 to 4 Lakh</option>
                    <option value="4 to 7 Lakh">4 to 7 Lakh</option>
                    <option value="7 to 10 Lakh">7 to 10 Lakh</option>
                    <option value="10 to 15 Lakh">10 to 15 Lakh</option>
                    <option value="15 to 20 Lakh">15 to 20 Lakh</option>
                    <option value="More than 20 Lakh">More than 20 Lakh</option>
                  </select>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerEducationCareer;
