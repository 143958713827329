import AdminLayout from "./components/AdminLayout";
import "./AdminSendProfile.css";
import React, { useEffect, useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import IMAGES from "../img/image";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import FilterForm from "../user/components/FilterForm";

const AdminSendProfile = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const [allUser, setAllUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userContacts, setUserContacts] = useState(null);
  const [mobileFilter, setMobileFilter] = useState(null);
  const [filter, setFilter] = useState({
    username: "",
    fromAge: "",
    toAge: "",
    fromHeight: "",
    toHeight: "",
    maritalStatus: "",
    qualification: "",
    salary: "",
    state: "",
    city: "",
    maslak: "",
  });

  //! MALE FEMALE SELECTION STATE
  const [male, setMale] = useState(null);
  const [maleName, setMaleName] = useState(null);
  const [female, setFemale] = useState(null);
  const [femaleName, setFemaleName] = useState(null);
  const [list, setList] = useState(false);
  const [data, setData] = useState(null);

  // Filter function
  const applyFilter = (user) => {
    const {
      username,
      fromAge,
      toAge,
      fromHeight,
      toHeight,
      maritalStatus,
      qualification,
      state,
      city,
      maslak,
    } = filter;

    // Function to extract feet from the height format
    const extractFeet = (height) => {
      return height.split("ft")[0].trim();
    };

    return (
      (username === "" ||
        user.username.toLowerCase().includes(username.toLowerCase())) &&
      (maritalStatus === "" || user.maritalStatus === maritalStatus) &&
      (fromAge === "" || user.age >= fromAge) &&
      (toAge === "" || user.age <= toAge) &&
      (fromHeight === "" || extractFeet(user.height) >= fromHeight) &&
      (toHeight === "" || extractFeet(user.height) <= toHeight) &&
      (state === "" || user.state === state) &&
      (city === "" || user.city === city) &&
      (qualification === "" || user.qualification === qualification) &&
      (maslak === "" || user.maslak === maslak)
    );
  };

  const filteredUsers = allUser?.filter(applyFilter);
  const maleUsers = filteredUsers?.filter((user) => user?.gender === "Male");
  const femaleUsers = filteredUsers?.filter(
    (user) => user?.gender === "Female"
  );

  const getAllUser = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/get-all-users", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllUser(res.data.data);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        getAllUser(res.data.data.gender);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function handleSendProfile() {
    if (!male || !female) {
      return message.error(`${!male ? "Select Male" : "Select Female"}`);
    }
    try {
      const res = await axios.post(
        "/api/admin/send-profile",
        { male, female },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setMale(null);
        setMaleName(null);
        setFemale(null);
        setFemaleName(null);
        setList(true);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getClientResponse() {
    try {
      const res = await axios.get("/api/admin/get-client-response", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setData(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleDirect(msId) {
    var encodedMsId = encodeURIComponent(msId);
    window.open(`https://halalrishtey.com/profile/${encodedMsId}`, "_blank");
    console.log("running");
  }

  useEffect(() => {
    getUserData();
    getClientResponse();
    if (user) {
      setUserContacts(user?.contacts);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [male, female]);

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Send Profiles</h3>
        <button onClick={() => setList(!list)}>
          {list ? "Hide List" : "See List"}
        </button>
      </div>
      <hr />
      <div className="selection-male-female">
        <div>
          <h6>Male: {male}</h6>
          <h6>Name: {maleName}</h6>
        </div>
        <div>
          <h6>Female: {female}</h6>
          <h6>Name: {femaleName}</h6>
        </div>
        <button className="register-btn" onClick={handleSendProfile}>
          Send Profile to Users
        </button>
      </div>
      <hr />
      {list ? (
        <div className="list-container">
          <table className="table">
            <thead>
              <tr>
                <th>User One</th>
                <th>User Two</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data?.map((item, index) => {
                  return (
                    <tr>
                      <td
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => handleDirect(item?.userOne)}
                      >
                        {item?.userOne}
                      </td>
                      <td
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => handleDirect(item?.userTwo)}
                      >
                        {item?.userTwo}
                      </td>
                      <td>{item?.status}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="admin-send-profile-container">
          <div className="d-none d-md-none d-lg-block filter-container">
            <FilterForm
              setShowFilter={setShowFilter}
              showFilter={showFilter}
              filter={filter}
              setFilter={setFilter}
              user={user}
            />
          </div>
          <div className="male-container">
            <div className="p-3 new-matches-container w-100">
              <div className="all-user-container w-100">
                {loading ? (
                  <div className="new-matches-users-container w-100">
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
                      return (
                        <div
                          className={`user-loading mb-lg-2 loading ${
                            loading && "active"
                          }`}
                          onClick={() => navigate(`/profile/${user?.msId}`)}
                        ></div>
                      );
                    })}
                  </div>
                ) : filteredUsers?.length === 0 ? (
                  <div
                    style={{
                      height: "90vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span className="text-center">
                      <ErrorIcon className="icon text-danger" />
                      <br />
                      <b>Oops "No results found"</b>
                      <br />
                      <b>
                        We suggest you to try with some other filter options
                      </b>
                    </span>
                  </div>
                ) : (
                  <div className="new-matches-users-container w-100">
                    {maleUsers?.map((user, index) => {
                      return (
                        <div
                          key={index}
                          className="new-match-users mb-lg-2 w-100"
                          onClick={() => {
                            setMale(user?.msId);
                            setMaleName(user?.username);
                          }}
                        >
                          <div className="new-match-user-image">
                            {user?.photoPrivacy === "Visible to all Members" ? (
                              <img
                                src={
                                  user?.images?.length > 0
                                    ? user?.images[0]
                                    : user?.gender === "Male"
                                    ? IMAGES.male
                                    : IMAGES.female
                                }
                                alt=""
                              />
                            ) : user?.photoPrivacy ===
                                "Only Visible to Premium Members" &&
                              userContacts > 0 ? (
                              <img
                                src={
                                  user?.images.length > 0
                                    ? user?.images[0]
                                    : user?.gender === "Male"
                                    ? IMAGES.male
                                    : IMAGES.female
                                }
                                alt=""
                              />
                            ) : (
                              <img
                                src={
                                  user?.gender === "Male"
                                    ? IMAGES.male
                                    : IMAGES.female
                                }
                                alt=""
                              />
                            )}
                          </div>
                          <div
                            className={`new-match-user-details ${
                              user?.contacts > 0 && "premium"
                            }`}
                          >
                            <div className="d-flex justify-content-end align-items-center mb-3">
                              {user?.idVerified === "approved" && (
                                <span className="id-verified">
                                  <small>
                                    <VerifiedIcon className="icon" />
                                    ID Verified
                                  </small>
                                </span>
                              )}
                              {user?.contacts > 0 && (
                                <span className="id-verified">
                                  <small>
                                    <WorkspacePremiumIcon className="icon text-warning" />
                                    Premium
                                  </small>
                                </span>
                              )}
                            </div>
                            {/* )} */}
                            <h6 className="m-0">{user?.username}</h6>
                            <span className="text-muted">
                              <small>
                                <small>
                                  <span>{user?.msId}</span>
                                </small>
                                <span>
                                  <small>
                                    {user?.lastLogin !== null && " | Active "}
                                    {user.lastLogin === null
                                      ? ""
                                      : new Date(
                                          user?.lastLogin
                                        ).toDateString() ===
                                        new Date().toDateString()
                                      ? `${new Date(
                                          user?.lastLogin
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: true,
                                        })}`
                                      : new Date(
                                          user?.lastLogin
                                        ).toDateString()}
                                  </small>
                                </span>
                              </small>
                            </span>
                            <span className="user-details">
                              {user?.age} {user?.age && "Yrs"}{" "}
                              {user?.height && " - "}
                              {user?.height?.split("-")[0]}{" "}
                              {user?.maritalStatus && " - "}{" "}
                              {user?.maritalStatus}
                              {user?.state && " - "}
                              {user?.state} {user?.city && " - "} {user?.city}{" "}
                              {user?.qualification && " - "}
                              {user?.qualification} {user?.maslak && " - "}{" "}
                              {user?.maslak}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="female-container">
            <div className="p-3 new-matches-container w-100">
              <div className="all-user-container w-100">
                {loading ? (
                  <div className="new-matches-users-container w-100">
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
                      return (
                        <div
                          className={`user-loading mb-lg-2 loading ${
                            loading && "active"
                          }`}
                          onClick={() => navigate(`/profile/${user?.msId}`)}
                        ></div>
                      );
                    })}
                  </div>
                ) : filteredUsers?.length === 0 ? (
                  <div
                    style={{
                      height: "90vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span className="text-center">
                      <ErrorIcon className="icon text-danger" />
                      <br />
                      <b>Oops "No results found"</b>
                      <br />
                      <b>
                        We suggest you to try with some other filter options
                      </b>
                    </span>
                  </div>
                ) : (
                  <div className="new-matches-users-container w-100">
                    {femaleUsers?.map((user, index) => {
                      return (
                        <div
                          key={index}
                          className="new-match-users mb-lg-2 w-100"
                          onClick={() => {
                            setFemale(user?.msId);
                            setFemaleName(user?.username);
                          }}
                        >
                          <div className="new-match-user-image">
                            {user?.photoPrivacy === "Visible to all Members" ? (
                              <img
                                src={
                                  user?.images?.length > 0
                                    ? user?.images[0]
                                    : user?.gender === "Male"
                                    ? IMAGES.male
                                    : IMAGES.female
                                }
                                alt=""
                              />
                            ) : user?.photoPrivacy ===
                                "Only Visible to Premium Members" &&
                              userContacts > 0 ? (
                              <img
                                src={
                                  user?.images.length > 0
                                    ? user?.images[0]
                                    : user?.gender === "Male"
                                    ? IMAGES.male
                                    : IMAGES.female
                                }
                                alt=""
                              />
                            ) : (
                              <img
                                src={
                                  user?.gender === "Male"
                                    ? IMAGES.male
                                    : IMAGES.female
                                }
                                alt=""
                              />
                            )}
                          </div>
                          <div
                            className={`new-match-user-details ${
                              user?.contacts > 0 && "premium"
                            }`}
                          >
                            <div className="d-flex justify-content-end align-items-center mb-3">
                              {user?.idVerified === "approved" && (
                                <span className="id-verified">
                                  <small>
                                    <VerifiedIcon className="icon" />
                                    ID Verified
                                  </small>
                                </span>
                              )}
                              {user?.contacts > 0 && (
                                <span className="id-verified">
                                  <small>
                                    <WorkspacePremiumIcon className="icon text-warning" />
                                    Premium
                                  </small>
                                </span>
                              )}
                            </div>
                            {/* )} */}
                            <h6 className="m-0">{user?.username}</h6>
                            <span className="text-muted">
                              <small>
                                <small>
                                  <span>{user?.msId}</span>
                                </small>
                                <span>
                                  <small>
                                    {user?.lastLogin !== null && " | Active "}
                                    {user.lastLogin === null
                                      ? ""
                                      : new Date(
                                          user?.lastLogin
                                        ).toDateString() ===
                                        new Date().toDateString()
                                      ? `${new Date(
                                          user?.lastLogin
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: true,
                                        })}`
                                      : new Date(
                                          user?.lastLogin
                                        ).toDateString()}
                                  </small>
                                </span>
                              </small>
                            </span>
                            <span className="user-details">
                              {user?.age} {user?.age && "Yrs"}{" "}
                              {user?.height && " - "}
                              {user?.height?.split("-")[0]}{" "}
                              {user?.maritalStatus && " - "}{" "}
                              {user?.maritalStatus}
                              {user?.state && " - "}
                              {user?.state} {user?.city && " - "} {user?.city}{" "}
                              {user?.qualification && " - "}
                              {user?.qualification} {user?.maslak && " - "}{" "}
                              {user?.maslak}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  );
};

export default AdminSendProfile;
