import React, { useEffect, useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Multiselect from "multiselect-react-dropdown";

const ReligiousInfo = ({ handleChange, activeUser, language, setLanguage }) => {
  const [field, setField] = useState(true);

  const languages = {
    options: [
      { name: "Hindi" },
      { name: "Urdu" },
      { name: "English" },
      { name: "Arabic" },
      { name: "Awadhi" },
      { name: "Bengali" },
      { name: "Gujrati" },
      { name: "Harynavi" },
      { name: "Kannada" },
      { name: "Kashmiri" },
      { name: "Khandesi" },
      { name: "Malyalam" },
      { name: "Manipuri" },
      { name: "Marathi" },
      { name: "Marwari" },
      { name: "Rajasthani" },
      { name: "Tamil" },
      { name: "Telugu" },
      { name: "Other" },
    ],
  };

  return (
    <div className="basic-info mt-3">
      <div className="title">
        <div className="d-flex justify-content-between">
          <span>Religious Background</span>
          <span>
            {field ? (
              <RemoveIcon onClick={() => setField(false)} />
            ) : (
              <AddIcon onClick={() => setField(true)} />
            )}
          </span>
        </div>
        <hr className="m-0 p-0 mt-2 mb-3" />
        <div className={`row fields-container ${field ? "" : "off"}`}>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Religion
              </label>
              <select
                onChange={(e) => handleChange("religion", e.target.value)}
                value={activeUser?.religion}
                name="religion"
                className="form-select"
              >
                <option value="Select">Select</option>
                <option value="Islam - Muslim">Islam - Muslim</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="form-fields mb-3">
              <label className="form-label" htmlFor="">
                Mother Tongue
              </label>
              <Multiselect
                id="category"
                className="multiselect-wrapper"
                isObject={false}
                onRemove={(event) => handleChange("language", event)}
                onSelect={(event) => handleChange("language", event)}
                options={languages.options.map((lang) => lang.name)}
                selectedValues={activeUser?.language?.map((item) => item)}
              />
            </div>
            {/* <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Mother Tongue
              </label>
              <select
                value={activeUser?.language}
                onChange={(e) => handleChange("complexion", e.target.value)}
                name="language"
                className="form-select"
              >
                <option value="Select">Select</option>
                <option value="Hindi">Hindi</option>
                <option value="Urdu">Urdu</option>
                <option value="English">English</option>
                <option value="Arabic">Arabic</option>
                <option value="Awadhi">Awadhi</option>
                <option value="Bengali">Bengali</option>
                <option value="Gujrati">Gujrati</option>
                <option value="Harynavi">Harynavi</option>
                <option value="Kannada">Kannada</option>
                <option value="Kashmiri">Kashmiri</option>
                <option value="Khandesi">Khandesi</option>
                <option value="Malyalam">Malyalam</option>
                <option value="Manipuri">Manipuri</option>
                <option value="Marathi">Marathi</option>
                <option value="Marwari">Marwari</option>
                <option value="Rajasthani">Rajasthani</option>
                <option value="Tamil">Tamil</option>
                <option value="Telugu">Telugu</option>
                <option value="Other">Other</option>
              </select>
            </div> */}
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Community
              </label>
              <select
                value={activeUser?.community}
                onChange={(e) => handleChange("community", e.target.value)}
                name="community"
                className="form-select"
              >
                <option value="Select">Select</option>
                <option value="Ansari">Ansari</option>
                <option value="Awan">Awan</option>
                <option value="Bengali">Bengali</option>
                <option value="Dawoodi Bohra">Dawoodi Bohra</option>
                <option value="Dekkani">Dekkani</option>
                <option value="Dudekula">Dudekula</option>
                <option value="Jat">Jat</option>
                <option value="Khoja">Khoja</option>
                <option value="Lebbai">Lebbai</option>
                <option value="Mapila">Mapila</option>
                <option value="Maraicar">Maraicar</option>
                <option value="Memon">Memon</option>
                <option value="Mughal">Mughal</option>
                <option value="Pathan">Pathan</option>
                <option value="Qureshi">Qureshi</option>
                <option value="Rajput">Rajput</option>
                <option value="Rowther">Rowther</option>
                <option value="Shafi">Shafi</option>
                <option value="Sheikh">Sheikh</option>
                <option value="Shia">Shia</option>
                <option value="Shia Bohra">Shia Bohra</option>
                <option value="Shia Imam Ismaili">Shia Imam Ismaili</option>
                <option value="Shia Ithna Ashariyyah">
                  Shia Ithna Ashariyyah
                </option>
                <option value="Shia Zaidi">Shia Zaidi</option>
                <option value="Siddiqui">Siddiqui</option>
                <option value="Sunni">Sunni</option>
                <option value="Sunni Ehle-Hadith">Sunni Ehle-Hadith</option>
                <option value="Sunni Hanafi">Sunni Hanafi</option>
                <option value="Sunni Hunbali">Sunni Hunbali</option>
                <option value="Sunni Maliki">Sunni Maliki</option>
                <option value="Sunni Shafi">Sunni Shafi</option>
                <option value="Syed">Syed</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          {activeUser?.community === "other" && (
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="">
                  Other Community
                </label>
                <input
                  onChange={(e) =>
                    handleChange("otherCommunity", e.target.value)
                  }
                  name="otherCommunity"
                  value={activeUser?.otherCommunity}
                  type="text"
                  placeholder="Enter your community"
                  className="form-control"
                />
              </div>
            </div>
          )}
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Maslak
              </label>
              <select
                value={activeUser?.maslak}
                onChange={(e) => handleChange("maslak", e.target.value)}
                name="maslak"
                className="form-select"
              >
                <option value="Select">Select</option>
                <option value="Deobandi - Jamati">Deobandi - Jamati</option>
                <option value="Sunni - Dargah - Fatiha">
                  Sunni - Dargah - Fatiha
                </option>
                <option value="Sunni - Non Dargah - Fatiha">
                  Sunni - Non Dargah - Fatiha
                </option>
                <option value="Salafi - Ahle hadith">
                  Salafi - Ahle hadith
                </option>
                <option value="Shia - Bohri - Khoja">
                  Shia - Bohri - Khoja
                </option>
                <option value="Don't want to mention">
                  Don't want to mention
                </option>
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Namaaz
              </label>
              <select
                value={activeUser?.namaaz}
                onChange={(e) => handleChange("namaaz", e.target.value)}
                name="namaaz"
                className="form-select"
              >
                <option value="Select">Select</option>
                <option value="Five Times">Five Times</option>
                <option value="Occasionally">Occasionally</option>
                <option value="Only Friday">Only Friday</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReligiousInfo;
