import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import "./AdminUsers.css";
import IMAGES from "../img/image";
import { Link, useNavigate, useParams } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const PremiumExpiryUsers = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [mail, setMail] = useState(false);
  const [email, setEmail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [msg, setMsg] = useState(null);
  const [queries, setQueries] = useState(null);
  const [queriesData, setQueriesData] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setloading] = useState(false);

  //! Mail Send
  const handleSendMail = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "/api/admin/send-mail-to-user",
        {
          email,
          subject,
          msg,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function getAllQueries() {
    try {
      setloading(true);
      const res = await axios.get("/api/admin/admin-queries", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setQueries(res.data.data.reverse());
        setQueriesData(res.data.data.reverse());
        setloading(false);
      } else {
        message.error(res.data.message);
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  }

  async function handleStatus(id, status, name, email) {
    if (status === "success") {
      try {
        const res = await axios.post(
          "/api/admin/update-query-status",
          {
            id: id,
            status: status,
            name: name,
            email: email,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.success) {
          message.success(res.data.message);
          getAllQueries();
        } else {
          message.error(res.data.success);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  function handleFilter() {
    if (status === "all") {
      setQueries(queriesData);
    } else {
      const filter = queriesData?.filter((item) =>
        item?.status?.toLowerCase().includes(status?.toLowerCase())
      );
      setQueries(filter);
    }
  }

  useEffect(() => {
    handleFilter();
  }, [status]);

  useEffect(() => {
    getAllQueries();
  }, []);

  function handleWhatsapp(mobile) {
    const whatsappLink = `https://wa.me/${mobile}`;
    window.open(whatsappLink, "_blank");
  }

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className={`mail-container ${mail && "active"}`}>
          <div className="form-fields">
            <p>Sending mail to: {email}</p>
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Enter Subject"
              name="subject"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
            />
            <textarea
              onChange={(e) => setMsg(e.target.value)}
              value={msg}
              placeholder="Enter message"
              className="form-control"
              name="msg"
              cols="30"
              rows="5"
            ></textarea>
            <button onClick={handleSendMail} className="register-btn mt-3">
              Send Message
            </button>
            <button
              onClick={() => setMail(false)}
              className="bg-danger register-btn mt-3 ms-2"
            >
              Go Back
            </button>
          </div>
        </div>
        <div className="page-title">
          <h3 className="m-0">Queries</h3>
          {/* <button>Add New User</button> */}
        </div>
        <hr />
        <div className="table-container">
          <div className="tools">
            <select
              name="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="all">All</option>
              <option value="pending">Pending</option>
              <option value="success">Success</option>
            </select>
          </div>
          {loading ? (
            <div className="loading-container">
              <div class="loader">
                <span class="loader-text">loading</span>
                <span class="load"></span>
              </div>
            </div>
          ) : (
            <table className="table user-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Message</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {queries &&
                  queries?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <small>{user?.name}</small>
                        </td>
                        <td style={{ cursor: "pointer" }}>
                          <small
                            onClick={() => {
                              setMail(true);
                              setEmail(user?.email);
                            }}
                          >
                            {user?.email}
                          </small>
                        </td>
                        <td onClick={() => handleWhatsapp(user?.mobile)}>
                          <small style={{ cursor: "pointer" }}>
                            {user?.mobile}
                          </small>
                        </td>
                        <td onClick={() => handleWhatsapp(user?.mobile)}>
                          <small style={{ cursor: "pointer" }}>
                            {user?.msg}
                          </small>
                        </td>
                        <td>
                          {user?.status === "pending" ? (
                            <select
                              name="status"
                              onChange={(e) =>
                                handleStatus(
                                  user?._id,
                                  e.target.value,
                                  user?.name,
                                  user?.email
                                )
                              }
                              value={user?.status}
                            >
                              <option value="success">Success</option>
                              <option value="pending">Pending</option>
                            </select>
                          ) : (
                            "Success"
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default PremiumExpiryUsers;
