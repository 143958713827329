import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import CancelIcon from "@mui/icons-material/Cancel";
import VerifiedIcon from "@mui/icons-material/Verified";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import IMAGES from "../img/image";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import "./MyMatches.css";

const MyMatches = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [allUser, setAllUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);

  const getAllUser = async (gender) => {
    try {
      setLoading(true);
      const res = await axios.post("/api/user/get-all-users", {
        gender: gender === "Male" ? "Female" : "Male",
      });
      if (res.data.success) {
        setAllUser(res.data.data);
      } else {
        message.error(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        getAllUser(res.data.data.gender);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  // ================================================= INFITE SCROLL
  const fetchData = async () => {
    try {
      const res = await axios.post("/api/user/get-all-users", {
        gender: user?.gender === "Male" ? "Female" : "Male",
      });

      if (res.data.success) {
        setAllUser((prevAllUser) => {
          const newUsers = res.data?.data?.filter((newUser) => {
            return !prevAllUser?.some(
              (existingUser) => existingUser?.id === newUser?.id
            );
          });
          // Check if there's more data
          if (newUsers?.length === 0) {
            setHasMoreData(false);
          }
          // Ensure prevAllUser is an array
          prevAllUser = prevAllUser || [];
          return [...prevAllUser, ...newUsers];
        });
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleScroll = () => {
    const windowHeight = window.innerHeight; // Get the window height
    const documentHeight = document.documentElement.scrollHeight;
    const windowBottom = windowHeight + window.scrollY;

    if (windowBottom >= documentHeight) {
      // Load more data
      fetchData();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Layout>
      <div className="new-matches-container container">
        <div className="d-flex justify-content-between align-items-end">
          <h6>Members according to your partner preferences</h6>
          <button
            className="register-btn"
            onClick={() => setShowFilter(!showFilter)}
          >
            Filter
          </button>
        </div>
        <hr />
        {/* ========================== ALL USER  */}
        {loading ? (
          "Loading.."
        ) : (
          <InfiniteScroll
            dataLength={allUser?.length || 0}
            next={fetchData}
            hasMore={hasMoreData}
            loader={
              <div
                className="spinner-grow text-success text-center mt-4"
                role="status"
              >
                <span className="sr-only d-block m-auto"></span>
              </div>
            }
          >
            <div className="new-matches-users-container">
              {allUser?.map((user, index) => {
                const userHeight = user?.height.split("-")[0].trim();
                const userReligion = user?.religion.split("-")[0].trim();
                return (
                  <div
                    key={index}
                    className="new-match-users mb-lg-2"
                    onClick={() => navigate(`/profile/${user?.msId}`)}
                  >
                    <div className="new-match-user-image">
                      {user?.images.length === 0 ? (
                        user?.gender === "Male" ? (
                          <img src={IMAGES?.male} alt="" />
                        ) : (
                          <img src={IMAGES?.female} alt="" />
                        )
                      ) : (
                        <img src={user?.images[0]} alt="" />
                      )}
                    </div>
                    <div className="new-match-user-details">
                      <h6>
                        {user?.username}{" "}
                        {user?.isVerified && <VerifiedIcon className="icon" />}
                      </h6>
                      <hr className="m-0 p-0" />
                      <div className="user-data">
                        <span>
                          <small>
                            {user?.maritalStatus}, {user?.age}, {userHeight}
                          </small>
                        </span>
                        <br />
                        <span>
                          <small>
                            {userReligion}, {user?.language} {user?.language}
                          </small>
                        </span>
                        <br />
                        <span>
                          <small>Mumbai, Maharshtra</small>
                        </span>
                      </div>
                      <hr className="m-0 p-0" />
                      <div className="d-flex justify-content-end">
                        <span className="mt-2">
                          <ThumbUpIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        )}
        {/* ========================== ALL USER  */}
        {/* ========================== filter container  */}
        <div className={`filter-container ${showFilter && "active"}`}>
          <div className="h-100">
            <div className="filter-close p-2 d-flex justify-content-end">
              <CancelIcon
                className="icon"
                onClick={() => setShowFilter(!showFilter)}
              />
            </div>
            hello
          </div>
        </div>
        {/* ========================== filter container  */}
      </div>
    </Layout>
  );
};

export default MyMatches;
