import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import EmailIcon from "@mui/icons-material/Email";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import VerifiedIcon from "@mui/icons-material/Verified";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./VerificationPage.css";
import { useDispatch, useSelector } from "react-redux";
import EmailVerify from "./components/EmailVerify/EmailVerify";
import MobileVerify from "./components/MobileVerify/MobileVerify";
import { Link } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import { setUser } from "../redux/features/userSlice";

const VerificationPage = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files;
    setFile(file);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("email", user?.email);
    // Check if a file is selected
    if (!file || file.length === 0) {
      message.error("No file is selected");
      return;
    }
    // Check if the file size is within the limit
    const uploadedFile = file[0];
    if (uploadedFile.size > 2 * 1024 * 1024) {
      message.error("File exceeds the 2MB size limit.");
      return;
    }
    formData.append("image", uploadedFile); // Append the single file to FormData
    setLoading(true);
    try {
      const res = await axios.post("/api/image/upload-idproof", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        dispatch(setUser(res.data.data));
        message.success(res.data.message);
        getUserData();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTab = (type) => {
    if (type === "email") {
      if (tab === 1) {
        setTab(0);
      } else {
        setTab(1);
      }
    } else if (type === "mobile") {
      if (tab === 2) {
        setTab(0);
      } else {
        setTab(2);
      }
    } else if (type === "id") {
      if (tab === 3) {
        setTab(0);
      } else {
        setTab(3);
      }
    } else {
      setTab(0);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
      <div className="verification-page-container">
        <div className="verification">
          <div
            className={`email-verification ${tab === 1 && "active"}`}
            onClick={() => handleTab("email")}
          >
            <span>
              <small>
                <EmailIcon className="me-2 text-danger" />
                Email Verification
              </small>
            </span>
            {user?.isActive === "Yes" ? (
              <span className="text-success">
                <small>
                  <CheckCircleIcon className="me-1" />
                  Verified
                </small>
              </span>
            ) : (
              <span className="text-danger">
                <small>
                  <PriorityHighIcon />
                  Pending
                </small>
              </span>
            )}
          </div>
          <hr className="mt-2" />
          <div
            onClick={() => handleTab("mobile")}
            className={`email-verification ${tab === 2 && "active"}`}
          >
            <span>
              <small>
                <MobileFriendlyIcon className="me-2 text-success" />
                Mobile Verification
              </small>
            </span>
            {user?.mobileVerified === "Yes" ? (
              <span className="text-success">
                <small>
                  <CheckCircleIcon className="me-1" />
                  Verified
                </small>
              </span>
            ) : (
              <span className="text-danger">
                <small>
                  <PriorityHighIcon />
                  Pending
                </small>
              </span>
            )}
          </div>
          <hr className="mt-2" />
          <div
            className={`email-verification ${tab === 3 && "active"}`}
            onClick={() => handleTab("id")}
          >
            <span>
              <small>
                <VerifiedIcon className="me-2 text-primary" />
                Govt ID Verification
              </small>
            </span>
            {user?.idVerified === "approved" ? (
              <span className="text-success">
                <small>
                  <CheckCircleIcon className="me-1" />
                  Verified
                </small>
              </span>
            ) : (
              <span className="text-danger">
                <small>
                  <PriorityHighIcon />
                  Pending
                </small>
              </span>
            )}
          </div>
          <hr className="mt-2" />
          <div className="verification-process">
            {tab === 1 && (
              <div className="verification-title py-3">
                {user?.isActive === "Yes" ? (
                  <div className="text-center">
                    Your email is verified{" "}
                    <CheckCircleIcon className="text-success" />
                  </div>
                ) : (
                  <EmailVerify user={user} tab={tab} />
                )}
              </div>
            )}
            {tab === 2 && (
              <div className="verification-title py-3">
                {user?.mobileVerified === "Yes" ? (
                  <div className="text-center">
                    Your Mobile is verified
                    <CheckCircleIcon className="text-success" />
                  </div>
                ) : user?.country === "India" ? (
                  <MobileVerify />
                ) : (
                  <div className="text-center">
                    <small>
                      Please Update your country!{" "}
                      <Link to="/edit-profile">click here</Link>
                    </small>
                  </div>
                )}
              </div>
            )}
            {tab === 3 && (
              <div className="verification-title py-3">
                {user?.idVerified === "approved" ? (
                  <div className="text-center">
                    Your ID is verified
                    <CheckCircleIcon className="text-success" />
                  </div>
                ) : (user?.idVerified === "" ||
                    user?.idVerified === "No" ||
                    user?.idVerified === "reject") &&
                  user?.idProof === null ? (
                  <div className="text-center">
                    <span>
                      <small>
                        You can upload your ID proof for profile verification
                        (Passport, Aadhar Card, Driving License, Other ID proof)
                      </small>
                    </span>
                    <input
                      onChange={handleFileChange}
                      type="file"
                      name="idproof"
                      className="id-proof-input mt-3"
                    />
                    <button
                      onClick={handleUpload}
                      className="register-btn text-start mt-2 mt-lg-0"
                    >
                      Upload
                    </button>
                  </div>
                ) : (
                  <div className="text-center">
                    Verification is under process
                    <PriorityHighIcon className="text-danger" />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VerificationPage;
