import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ProfileCard from "./components/MyProfile.js/ProfileCard";
import AboutMyself from "./components/MyProfile.js/AboutMyself";
import AboutPartner from "./components/MyProfile.js/AboutPartner";
import { message } from "antd";
import "./MyProfile.css";
import { useReactToPrint } from "react-to-print";

const MyProfile = () => {
  const { user } = useSelector((state) => state.user);
  const params = useParams();
  const navigate = useNavigate();
  const [aboutTab, setAboutTab] = useState(0);
  const [profile, setProfile] = useState(0);
  const [imageLoading, setImageLoading] = useState(false);

  const getProfileData = async () => {
    try {
      setImageLoading(true);
      const res = await axios.post(
        "/api/user/get-profile-data",
        {
          msId: params.id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setProfile(res.data.data);
        setImageLoading(false);
      } else {
        setImageLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      setImageLoading(false);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const data = params.id ? profile : user;

  return (
    <Layout>
      <div className="profile-container">
        <div className="name-container mb-3 d-none d-lg-block">
          <div className="name">
            {data?.username} ({data?.msId})
            <button
              className="register-btn"
              onClick={() => navigate(`/profile-pdf/${data?.msId}`)}
            >
              Download Biodata
            </button>
          </div>
        </div>
        <ProfileCard
          activeUser={user}
          data={data}
          imageLoading={imageLoading}
          setImageLoading={setImageLoading}
        />
        <hr />
        <div className="d-flex">
          {!params.id && (
            <>
              <button
                onClick={() => setAboutTab(0)}
                className={`me-2 about-btn ${aboutTab === 0 && "active"}`}
              >
                About Myself
              </button>

              <button
                onClick={() => setAboutTab(1)}
                className={`about-btn ${aboutTab === 1 && "active"}`}
              >
                Partner Preference
              </button>
            </>
          )}
        </div>
        {aboutTab === 0 ? (
          <AboutMyself data={data} />
        ) : (
          <AboutPartner data={data} />
        )}
      </div>
    </Layout>
  );
};

export default MyProfile;
