import React, { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Multiselect from "multiselect-react-dropdown";

const PartnerBasicInfo = ({ handleChange, activeUser, setActiveUser }) => {
  // Weight
  const weighOptions = [];
  for (let i = 35; i <= 100; i++) {
    weighOptions.push(<option key={i}>{i} Kg</option>);
  }
  // Height
  const heightOptions = [];
  for (let feet = 4; feet <= 7; feet++) {
    for (let inches = 0; inches <= 11; inches++) {
      if (inches !== 0) {
        const totalInches = feet * 12 + inches;
        const cm = Math.round(totalInches * 2.54);
        heightOptions.push(
          <option
            key={`${feet}-${inches}`}
            value={`${feet} ft ${inches} in - ${cm} cm`}
          >
            {feet} feet {inches} inches - {cm} cm
          </option>
        );
      } else {
        const cm = Math.round(feet * 30.48);
        heightOptions.push(
          <option key={`${feet}-${inches}`} value={`${feet} ft - ${cm} cm`}>
            {feet} feet - {cm} cm
          </option>
        );
      }
    }
  }
  // Age
  const AgeOptions = [];
  for (let i = 18; i <= 70; i++) {
    AgeOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  const [field, setField] = useState(true);

  const languages = {
    options: [
      { name: "Hindi" },
      { name: "Urdu" },
      { name: "English" },
      { name: "Arabic" },
      { name: "Awadhi" },
      { name: "Bengali" },
      { name: "Gujrati" },
      { name: "Harynavi" },
      { name: "Kannada" },
      { name: "Kashmiri" },
      { name: "Khandesi" },
      { name: "Malyalam" },
      { name: "Manipuri" },
      { name: "Marathi" },
      { name: "Marwari" },
      { name: "Rajasthani" },
      { name: "Tamil" },
      { name: "Telugu" },
      { name: "Other" },
    ],
  };

  return (
    <div className="basic-info">
      <div className="title">
        <div className="d-flex justify-content-between">
          <span>Partner Basic & Lifestyle Info</span>
          <span>
            {field ? (
              <RemoveIcon onClick={() => setField(false)} />
            ) : (
              <AddIcon onClick={() => setField(true)} />
            )}
          </span>
        </div>
        <hr className="m-0 p-0 mt-2 mb-3" />
        <div className={`row fields-container ${field ? "" : "off"}`}>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Partner Age From
              </label>
              <select
                value={activeUser?.partnerAgeFrom}
                onChange={(e) => handleChange("partnerAgeFrom", e.target.value)}
                name="partnerAgeFrom"
                className="form-select"
              >
                <option value="Select">Select</option>
                {AgeOptions}
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Partner Age To
              </label>
              <select
                value={activeUser?.partnerAgeTo}
                onChange={(e) => handleChange("partnerAgeTo", e.target.value)}
                name="partnerAgeTo"
                className="form-select"
              >
                <option value="Select">Select</option>
                {AgeOptions}
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Partner Marital Status
              </label>
              <select
                value={activeUser?.partnerMaritalStatus}
                onChange={(e) =>
                  handleChange("partnerMaritalStatus", e.target.value)
                }
                name="partnerMaritalStatus"
                className="form-select"
              >
                <option value="Single (Never Married)">
                  Single (Never Married)
                </option>
                <option value="Second Marriage">Second Marriage</option>
                <option value="Divorced">Divorced</option>
                <option value="Khula">Khula</option>
                <option value="Widowed">Widowed</option>
                <option value="Widower">Widower</option>{" "}
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Partner Religion
              </label>
              <select
                value={activeUser?.partnerReligion}
                onChange={(e) =>
                  handleChange("partnerReligion", e.target.value)
                }
                name="partnerReligion"
                className="form-select"
              >
                <option value="Select">Select</option>
                <option value="Islam - Muslim">Islam - Muslim</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="form-fields mb-3">
              <label className="form-label" htmlFor="">
                Mother Tongue
              </label>
              <Multiselect
                id="category"
                className="multiselect-wrapper"
                isObject={false}
                onRemove={(event) => handleChange("partnerLanguage", event)}
                onSelect={(event) => handleChange("partnerLanguage", event)}
                options={languages?.options.map((lang) => lang?.name)}
                selectedValues={activeUser?.partnerLanguage?.map(
                  (item) => item
                )}
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Partner Maslak
              </label>
              <select
                value={activeUser?.partnerMaslak}
                onChange={(e) => handleChange("partnerMaslak", e.target.value)}
                name="partnerMaslak"
                className="form-select"
              >
                <option value="Select">Select</option>
                <option value="Deobandi - Jamati">Deobandi - Jamati</option>
                <option value="Sunni - Dargah - Fatiha">
                  Sunni - Dargah - Fatiha
                </option>
                <option value="Sunni - Non Dargah - Fatiha">
                  Sunni - Non Dargah - Fatiha
                </option>
                <option value="Salafi - Ahle hadith">
                  Salafi - Ahle hadith
                </option>
                <option value="Shia - Bohri - Khoja">
                  Shia - Bohri - Khoja
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerBasicInfo;
