import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "./ImageModal.css";
import Slider from "react-slick";

const ImageModal = ({
  data,
  imageModal,
  setImageModal,
  userImage,
  setUserImage,
}) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={`image-modal-container ${imageModal && "active"}`}>
      <div className="modal-black-bg showImageModal">
        <div className="display-image">
          <img
            src={data?.images?.length > 0 ? data?.images[userImage] : ""}
            alt=""
          />
          {data?.images?.length <= 0 && (
            <span className="text-white">No Images Uploaded</span>
          )}
        </div>
        <div className="image-slides">
          {data?.images?.map((image, index) => {
            return (
              <img onClick={() => setUserImage(index)} src={image} alt="img" />
            );
          })}
        </div>
        <HighlightOffIcon
          onClick={() => setImageModal(!imageModal)}
          className="icon"
        />
      </div>
      <div className="modal-black-bg-mobile d-lg-none">
        <div className="mobile-display-image">
          <Slider {...settings}>
            {data?.images?.map((img, index) => {
              return <img src={img} alt="" />;
            })}
          </Slider>
        </div>
        <HighlightOffIcon
          onClick={() => setImageModal(!imageModal)}
          className="icon"
        />
      </div>
    </div>
  );
};

export default ImageModal;
