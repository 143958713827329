import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useSelector } from "react-redux";
import "./NewMatches.css";
import axios from "axios";
import { message } from "antd";
import IMAGES from "../img/image";
import FilterForm from "./components/FilterForm";
import Pagination from "./components/Pagination";
import { useNavigate } from "react-router-dom";
import SearchForm from "./components/SearchForm";

const Search = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [allUser, setAllUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    fromAge: "",
    toAge: "",
    maritalStatus: "",
    qualification: "",
    salary: "",
    state: "",
    city: "",
  });

  console.log(filter);

  // Filter function
  const applyFilter = (user) => {
    const {
      fromAge,
      toAge,
      maritalStatus,
      qualification,
      salary,
      state,
      city,
    } = filter;

    return (
      (fromAge === "" || user.age >= fromAge) &&
      (toAge === "" || user.age <= toAge) &&
      (maritalStatus === "" || user.maritalStatus === maritalStatus) &&
      (qualification === "" || user.qualification === qualification) &&
      (salary === "" || user.salary === salary) &&
      (state === "" || user.state === state) &&
      (city === "" || user.city === city)
    );
  };

  const isFilterActive =
    filter.fromAge ||
    filter.toAge ||
    filter.maritalStatus ||
    filter.qualification ||
    filter.salary ||
    filter.state ||
    filter.city;

  const filteredUsers = allUser?.filter(applyFilter);

  const getAllUser = async (gender) => {
    try {
      setLoading(true);
      const res = await axios.post("/api/user/get-all-users", {
        gender: gender === "Male" ? "Female" : "Male",
        country: user?.country,
      });
      if (res.data.success) {
        setAllUser(res.data.data);
      } else {
        message.error(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        getAllUser(res.data.data.gender);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const totalItems = filteredUsers?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const paginatedUsers = filteredUsers?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Layout>
      <div className="new-matches-container container">
        <div className="filter-container">
          <FilterForm
            setShowFilter={setShowFilter}
            showFilter={showFilter}
            filter={filter}
            setFilter={setFilter}
            user={user}
          />
        </div>
        <div className="all-user-container">
          {isFilterActive && (
            <div className="new-matches-users-container">
              {paginatedUsers?.map((user, index) => {
                const userHeight = user?.height?.split(" -")[0].trim();
                const userReligion = user?.religion?.split(" -")[0].trim();
                return (
                  <div
                    key={index}
                    className="new-match-users mb-lg-2"
                    onClick={() => navigate(`/profile/${user?.msId}`)}
                  >
                    <div className="new-match-user-image">
                      {user?.photoPrivacy === "Visible to all Members" ? (
                        <img
                          src={
                            user?.images?.length > 0
                              ? user?.images[0]
                              : user?.gender === "Male"
                              ? IMAGES.male
                              : IMAGES.female
                          }
                          alt=""
                        />
                      ) : user?.photoPrivacy ===
                          "Only Visible to Premium Members" &&
                        user?.contacts > 0 ? (
                        <img
                          src={
                            user?.images.length > 0
                              ? user?.images[0]
                              : user?.gender === "Male"
                              ? IMAGES.male
                              : IMAGES.female
                          }
                          alt=""
                        />
                      ) : (
                        <img
                          src={
                            user?.gender === "Male"
                              ? IMAGES.male
                              : IMAGES.female
                          }
                          alt=""
                        />
                      )}
                    </div>
                    <div className="new-match-user-details">
                      <div className="d-flex justify-content-end align-items-center">
                        {user?.idVerified === "Yes" && (
                          <span className="id-verified">
                            <VerifiedIcon className="icon" />
                            ID Verified
                          </span>
                        )}
                        {user?.contacts > 0 && (
                          <span className="id-verified">
                            <WorkspacePremiumIcon className="icon" />
                            Premium
                          </span>
                        )}
                      </div>
                      {/* )} */}
                      <h6 className="m-0">{user?.username}</h6>
                      <span className="text-muted">
                        <small>
                          <span>{user?.msId}</span>
                          {" | "}
                          <span>Last seen few hours ago</span>
                        </small>
                      </span>
                      <span className="user-details">
                        {user?.age} {user?.age && "Yrs"} {user?.height && " - "}
                        {user?.height?.split("-")[0]}{" "}
                        {user?.maritalStatus && " - "} {user?.maritalStatus}
                        {user?.state && " - "}
                        {user?.state} {user?.city && " - "} {user?.city}{" "}
                        {user?.qualification && " - "}
                        {user?.qualification} {user?.maslak && " - "}{" "}
                        {user?.maslak}
                      </span>
                      <div className="user-data">
                        <button className="view-profile-btn">
                          View Profile
                        </button>
                      </div>
                      {/* <div className="premium mt-3">
                        {user?.idVerified === "Yes" && (
                          <div>
                            <VerifiedIcon className="icon verified" />
                            <span>ID Verified</span>
                          </div>
                        )}
                        {user?.contacts > 0 && (
                          <div>
                            <WorkspacePremiumIcon className="icon" />
                            <span>Premium</span>
                          </div>
                        )}
                      </div> */}
                      {/* <div className="user-data">
                        <span>
                          <small>{user?.qualification},</small>
                        </span>
                      </div> */}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {!isFilterActive && (
            <div
              style={{ minHeight: "350px" }}
              className="no-result-container d-flex flex-column justify-content-center align-items-center"
            >
              Users will appear here
            </div>
          )}
        </div>
      </div>
      <div className="pagination-container">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default Search;
