import React from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AboutPartner = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  return (
    <div className="my-3 about-myself-container">
      {/* Basic info & Lifestyle  */}
      <div className="user-field mb-4">
        <div className="about-myself-heading">
          <h6>Basic & Lifestyle</h6>
          <span onClick={() => navigate("/partner-preferences")}>
            Edit
            <PlayArrowIcon className="icon" />
          </span>
        </div>
        <hr className="m-0 mb-2" />
        <div className="user-answer">
          <div className="user-answer-left">
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Age From</small>
              </span>
              <span className="ans">
                : <small>{user?.partnerAgeFrom || "Doesn't Matter"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Age To</small>
              </span>
              <span className="ans">
                : <small>{user?.partnerAgeTo || "Doesn't Matter"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Partner Marital Status</small>
              </span>
              <span className="ans">
                :{" "}
                <small>{user?.partnerMaritalStatus || "Doesn't Matter"}</small>
              </span>
            </div>
          </div>
          <div className="user-answer-right">
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Religion</small>
              </span>
              <span className="ans">
                : <small>{user?.partnerReligion || "Doesn't Matter"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Mother Tongue</small>
              </span>
              <span className="ans">
                : <small>{user?.partnerLanguage || "Doesn't Matter"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Diet</small>
              </span>
              <span className="ans">
                : <small>{user?.partnerDiet || "Doesn't Matter"}</small>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Location  */}
      <div className="user-field mb-4">
        <div className="about-myself-heading">
          <h6>Location Details</h6>
          <span onClick={() => navigate("/partner-preferences")}>
            Edit
            <PlayArrowIcon className="icon" />
          </span>
        </div>
        <hr className="m-0 mb-2" />
        <div className="user-answer">
          <div className="user-answer-left" style={{ border: "0" }}>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Country Living In</small>
              </span>
              <span className="ans">
                : <small>{user?.partnerCountry || "Doesn't Matter"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>State</small>
              </span>
              <span className="ans">
                : <small>{user?.partnerState || "Doesn't Matter"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>City</small>
              </span>
              <span className="ans">
                : <small>{user?.partnerCity || "Doesn't Matter"}</small>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Education & Careeer  */}
      <div className="user-field mb-4">
        <div className="about-myself-heading">
          <h6>Education & Careeer</h6>
          <span onClick={() => navigate("/partner-preferences")}>
            Edit
            <PlayArrowIcon className="icon" />
          </span>
        </div>
        <hr className="m-0 mb-2" />
        <div className="user-answer">
          <div className="user-answer-left">
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Partner Education</small>
              </span>
              <span className="ans">
                : <small>{user?.partnerEducation || "Doesn't Matter"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Partner working with</small>
              </span>
              <span className="ans">
                : <small>{user?.partnerWorkingWith || "Doesn't Matter"}</small>
              </span>
            </div>
          </div>
          <div className="user-answer-right">
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Partner employed as</small>
              </span>
              <span className="ans">
                : <small>{user?.partnerEmployedAs || "Doesn't Matter"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Partner annual salary</small>
              </span>
              <span className="ans">
                : <small>{user?.partnerSalary || "Doesn't Matter"}</small>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPartner;
