import React from "react";
import IMAGES from "../../img/image";
import trusted from "../../img/home/trusted.svg";
import register from "../../img/home/register.svg";
import privacy from "../../img/home/privacy.svg";
import verified from "../../img/home/verified.svg";
import "./HowItWorks.css";

const HowItWorks = () => {
  return (
    <div
      className="container-fluid p-4"
      style={{ borderTopLeftRadius: "3px", borderTopRightRadius: "3px" }}
    >
      <div className="my-5 ">
        <h1
          className="text-center fs-1 fw-bold poppins"
          style={{ color: "#26bd68" }}
        >
          Why Choose Us?
        </h1>
        <div
          className="text-center mt-3"
          style={{
            width: "100px",
            height: "3px",
            backgroundColor: "#26bd68",
            margin: "auto",
          }}
        ></div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-3 col-lg-3 col-12 text-center mb-4">
            <img width={100} src={trusted} alt="" />
            <h5 className="my-3 poppins">Trusted Website</h5>
            <p>Our largest number of profiles increases your chances.</p>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3 col-12 text-center mb-4">
            <img width={100} src={register} alt="" />
            <h5 className="my-3 poppins">Free Registration</h5>
            <p>Registering with us is completely free and simple.</p>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3 col-12 text-center mb-4">
            <img width={100} src={verified} alt="" />
            <h5 className="my-3 poppins">Verified Profiles</h5>
            <p>Our experts manually screen and validate each profile.</p>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3 col-12 text-center mb-4">
            <img width={100} src={privacy} alt="" />
            <h5 className="my-3 poppins">100% Privacy</h5>
            <p>We follow best practices to keep your data safe.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
