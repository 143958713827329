import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import axios from "axios";

const AdminAdvertisement = () => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);

  // handle change
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // handle upload
  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      alert("Please select a file");
      return;
    }
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await axios.post("/api/admin/post-ads", formData);
      console.log(response.data.message);
      alert("Image uploaded successfully");
    } catch (error) {
      console.error("Error:", error);
      alert("Error uploading image");
    }
  };

  // handle delete
  const handleDelete = async (imagePath) => {
    try {
      const res = await axios.post("/api/admin/delete-ads", {
        imagePath: imagePath,
      });

      if (res.data.success) {
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  // get all ads
  const getAds = async () => {
    try {
      const res = await axios.get("/api/admin/get-ads");
      if (res.data.success) {
        setData(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
    getAds();
  }, []);
  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <form action="" onSubmit={handleUpload}>
            <h3 className="m-0">Upload</h3>
            <input
              aria-label="Select Image"
              className="border"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="image"
              required
              multiple
              onChange={handleFileChange}
            />
            <button type="submit">Submit</button>
          </form>
        </div>
        <div className="table-container">
          <div className="tools"></div>
          <table className="table user-table">
            <thead>
              <tr>
                <th>Srno</th>
                <th>Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <img src={item.path} alt="" />
                      </td>
                      <td>
                        <DeleteForeverIcon
                          onClick={() => handleDelete(item.path)}
                          style={{ cursor: "pointer" }}
                          className="text-danger"
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminAdvertisement;
