import React, { useEffect, useState } from "react";
import OtpInput from "otp-input-react";
import axios from "axios";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux/features/userSlice";

const EmailVerify = ({ user }) => {
  const [otp, setOtp] = useState();
  const [userEnteredOtp, setUserEnteredOtp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [otpSending, setOtpSending] = useState(false);
  const [email, setEmail] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // OTP VERIFY
  const onOTPVerify = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post("/api/user/verify-otp", {
        userEnteredOtp,
        email,
      });
      if (res.data.success) {
        if (location.pathname === "/complete-profile") {
          axios.post("/api/user/user-active", { email });
          message.success("Profile Created Succesfull");
          localStorage.removeItem("email");
          navigate("/login");
        } else {
          message.success(res.data.message);
          dispatch(setUser(res.data.data));
          getUserData();
        }
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // OTP RESEND
  const handleResendOtp = async (e) => {
    e.preventDefault();
    const msg = "Hey! Your Email Verification OTP is";
    try {
      setOtpSending(true);
      const res = await axios.post("/api/user/send-otp", { email, msg });
      if (res.data.success) {
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
      setOtpSending(false);
    } catch (error) {
      console.log(error);
      setOtpSending(false);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          dispatch(setUser(res.data.data));
        } else {
          localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("email")) {
      setEmail(localStorage.getItem("email"));
    } else {
      setEmail(user?.email);
    }
  }, []);

  return (
    <form
      className={`${
        location.pathname === "/complete-profile" && "complete-profile-form"
      }`}
    >
      <div className="row">
        <div className="text-center"></div>
        <div className="col-12 text-center col-sm-12 col-md-12 col-lg-12">
          <div className="mb-3 form-fields">
            <label className="form-label" htmlFor="">
              <small>
                {user?.email}
                <br />
                Enter Verification OTP*
              </small>
            </label>
            <div className="d-flex justify-content-center">
              <OtpInput
                OtpType="number"
                disabled={false}
                autoFocus
                className="otp-container"
                OTPLength={6}
                value={userEnteredOtp}
                onChange={setUserEnteredOtp}
              ></OtpInput>
            </div>
          </div>
          <button onClick={onOTPVerify} className="continue-btn">
            {loading ? (
              <>
                <span
                  className="me-2 spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Verifying</span>
              </>
            ) : (
              <span>Verify OTP</span>
            )}
          </button>
        </div>
      </div>
      <p className="text-center mt-2">
        Didn't Recevied OTP?{" "}
        <span
          style={{ cursor: "pointer" }}
          className="text-primary"
          onClick={handleResendOtp}
        >
          {otpSending ? "Sending.." : "Resend OTP"}
        </span>
        <br />
        <small className="text-danger">Note: check spam folder as well</small>
      </p>
    </form>
  );
};

export default EmailVerify;
