import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPass from "./pages/ForgotPass";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Dashboard from "./user/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import ResetPassword from "./pages/ResetPassword";
import CompleteProfile from "./pages/CompleteProfile";
import MyProfile from "./user/MyProfile";
import MyPhotos from "./user/MyPhotos";
import TodayMatches from "./user/TodayMatches";
import PartnerPreferences from "./user/PartnerPreferences";
import Settings from "./user/Settings";
import Search from "./user/Search";
import Inbox from "./user/Inbox";
import EditProfile from "./user/EditProfile";
import PremiumPlans from "./pages/PremiumPlans";
import EditPartnerProfile from "./user/EditPartnerProfile";
import MyContactDetails from "./user/components/Contact/MyContactDetails";
import NewMatches from "./user/NewMatches";
import MyMatches from "./user/MyMatches";
import NearMe from "./user/NearMe";
import InboxAccepted from "./user/InboxAccepted";
import InboxSent from "./user/InboxSent";
import InboxDeleted from "./user/InboxDeleted";
import AdvanceSearch from "./user/AdvanceSearch";
import VerificationPage from "./user/VerificationPage";
import AdminDashboard from "./admin/AdminDashboard";
import AdminUsers from "./admin/AdminUsers";
import EditUser from "./admin/EditUser";
import AdminAdvertisement from "./admin/AdminAdvertisement";
import AdminPayments from "./admin/AdminPayments";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import AdminPlans from "./admin/AdminPlans";
import { useEffect } from "react";
import AdminBulkEmail from "./admin/AdminBulkEmail";
import ProfilePdfGenerator from "./pages/ProfilePdfGenerator";
import AdminBlog from "./admin/AdminBlog";
import BlogPage from "./pages/BlogPage";
import PremiumExpiringUsers from "./admin/PremiumExpiringUsers";
import AdminSendProfile from "./admin/AdminSendProfile";
import AdminQueries from "./admin/AdminQueries";

function App() {
  // reset OTP limitattions
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  // to reset otp limitations on a new day
  function ResetResendOtp() {
    useEffect(() => {
      const currentDate = getCurrentDate();
      const storedDate = localStorage.getItem("storedDate");
      if (!storedDate) {
        localStorage.setItem("storedDate", currentDate);
      } else if (currentDate !== storedDate) {
        localStorage.setItem("resendOtp", "0");
        localStorage.setItem("storedDate", currentDate);
      }
    }, []);
  }
  ResetResendOtp();

  return (
    <BrowserRouter>
      <Routes>
        {/* pages */}
        <Route
          path="/"
          element={
            <PublicRoute>
              <Home />
            </PublicRoute>
          }
        />
        <Route
          path="/register/:referCode?"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route path="/forgot-password" element={<ForgotPass />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog/:name?" element={<BlogPage />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/service" element={<Service />} /> */}
        <Route path="/complete-profile" element={<CompleteProfile />} />
        <Route path="/premium-plans" element={<PremiumPlans />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        {/* ======================== USER PAGES =============================== */}
        {/* ========== EMAIL VERIFY */}
        <Route
          path="/profile-pdf/:id?"
          element={
            // <ProtectedRoute>
            <ProfilePdfGenerator />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/verification"
          element={
            <ProtectedRoute>
              <VerificationPage />
            </ProtectedRoute>
          }
        />
        {/* ========== EMAIL VERIFY */}
        <Route
          path="/user-dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/:id?"
          element={
            <ProtectedRoute>
              <MyProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-profile"
          element={
            <ProtectedRoute>
              <EditProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner-preferences"
          element={
            <ProtectedRoute>
              <EditPartnerProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-contact"
          element={
            <ProtectedRoute>
              <MyContactDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-photos"
          element={
            <ProtectedRoute>
              <MyPhotos />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner-preferences"
          element={
            <ProtectedRoute>
              <PartnerPreferences />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        {/* ===== Matches ==== */}
        <Route
          path="/ms-users"
          element={
            <ProtectedRoute>
              <NewMatches />
            </ProtectedRoute>
          }
        />
        <Route
          path="/today-matches"
          element={
            <ProtectedRoute>
              <TodayMatches />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-matches"
          element={
            <ProtectedRoute>
              <MyMatches />
            </ProtectedRoute>
          }
        />
        <Route
          path="/near-me"
          element={
            <ProtectedRoute>
              <NearMe />
            </ProtectedRoute>
          }
        />
        {/* ===== Search ==== */}
        <Route
          path="/search"
          element={
            <ProtectedRoute>
              <Search />
            </ProtectedRoute>
          }
        />
        <Route
          path="/advance-search"
          element={
            <ProtectedRoute>
              <AdvanceSearch />
            </ProtectedRoute>
          }
        />
        {/* ===== Inbox ==== */}
        {/* ===== Inbox ==== */}
        <Route
          path="/inbox-received"
          element={
            <ProtectedRoute>
              <Inbox />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inbox-accepted"
          element={
            <ProtectedRoute>
              <InboxAccepted />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inbox-sent"
          element={
            <ProtectedRoute>
              <InboxSent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inbox-deleted"
          element={
            <ProtectedRoute>
              <InboxDeleted />
            </ProtectedRoute>
          }
        />
        {/* ===== Inbox ==== */}
        {/* ======================== USER PAGES =============================== */}
        {/* ======================== ADMIN PAGES =============================== */}
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-users/:type?"
          element={
            <ProtectedRoute>
              <AdminUsers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-edit-user/:id?"
          element={
            <ProtectedRoute>
              <EditUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-advertisement"
          element={
            <ProtectedRoute>
              <AdminAdvertisement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-payments"
          element={
            <ProtectedRoute>
              <AdminPayments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-plans/:id?"
          element={
            <ProtectedRoute>
              <AdminPlans />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-bulk-email"
          element={
            <ProtectedRoute>
              <AdminBulkEmail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-blog"
          element={
            <ProtectedRoute>
              <AdminBlog />
            </ProtectedRoute>
          }
        />{" "}
        <Route
          path="/premium-expiry"
          element={
            <ProtectedRoute>
              <PremiumExpiringUsers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-send-profile"
          element={
            <ProtectedRoute>
              <AdminSendProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-queries"
          element={
            <ProtectedRoute>
              <AdminQueries />
            </ProtectedRoute>
          }
        />
        {/* ======================== ADMIN PAGES =============================== */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
