import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./EditProfile.css";
import { message } from "antd";
import { setUser } from "../redux/features/userSlice";
import Layout from "../components/Layout/Layout";
import PartnerBasicInfo from "./components/EditPartner/PartnerBasicInfo";
import PartnerLocation from "./components/EditPartner/PartnerLocation";
import PartnerEducationCareer from "./components/EditPartner/PartnerEducationCareer";

const EditPartnerProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [activeUser, setActiveUser] = useState(null);

  const handleChange = (name, value) => {
    setActiveUser({ ...activeUser, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/update-user", activeUser, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        dispatch(setUser(res.data.data));
        navigate("/user-dashboard");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setActiveUser(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
      <div className="edit-profile-container">
        <div className="page-heading">
          <span>
            <b>Edit Partner Preference</b>
          </span>
          <span>
            <small>
              Fields in bold cannot be edited. Please contact customer care for
              any queries.
            </small>
          </span>
        </div>
        <form>
          <PartnerBasicInfo
            handleChange={handleChange}
            activeUser={activeUser}
            setActiveUser={setActiveUser}
          />
          <PartnerLocation
            handleChange={handleChange}
            activeUser={activeUser}
            setActiveUser={setActiveUser}
          />
          <PartnerEducationCareer
            handleChange={handleChange}
            activeUser={activeUser}
            setActiveUser={setActiveUser}
          />
          <div
            type="submit"
            className="register-btn mt-3 text-center update-btn"
            onClick={handleSubmit}
          >
            Update
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default EditPartnerProfile;
