import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import "./TodayMatches.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import CancelIcon from "@mui/icons-material/Cancel";
import IMAGES from "../img/image";
import { setUser } from "../redux/features/userSlice";
import "./MyProfile.css";
import ImageModal from "./components/ImageModal";
import ErrorIcon from "@mui/icons-material/Error";

const TodayMatches = () => {
  const TodayMatchContainer = () => {
    return (
      <div className="today-match-user-container">
        <div className="today-match-user-img">
          {activeUser?.images?.length === 0 ? (
            activeUser?.gender === "Male" ? (
              <img src={IMAGES?.male} alt="" />
            ) : (
              <img src={IMAGES?.female} alt="" />
            )
          ) : (
            <img
              onClick={() => setImageModal(!imageModal)}
              src={activeUser?.images[userProfileImage]}
              alt="loading.."
            />
          )}

          {activeUser?.images && activeUser?.images.length > 1 && (
            <>
              <div className="arrow left-arrow">
                <ArrowCircleLeftIcon
                  onClick={() => handleImage("left")}
                  className="icon"
                />
              </div>
              <div className="arrow right-arrow">
                <ArrowCircleRightIcon
                  onClick={() => handleImage("right")}
                  className="icon"
                />
              </div>
            </>
          )}

          {showContact ? (
            <div className="today-match-show-contact-container d-none d-md-none d-lg-block border p-3">
              <span className="text-center text-white">
                <i>
                  <small>You can contact directly</small>
                </i>
              </span>
              <Link to={`tel:${userContact}`}>
                <CallIcon className="icon call-btn" />
              </Link>
              <Link to={`https://wa.me/${userContact}`}>
                <WhatsAppIcon className="icon whatsapp-btn" />
              </Link>
            </div>
          ) : showContactBtn ? (
            <div className="d-none d-md-none d-lg-block text-center today-match-user-contact-container">
              {parseInt(activeUser?.contacts) <= 0 && (
                <span>
                  <small>
                    <Link to="/premium-plans">Upgrade</Link> to Contact
                  </small>
                </span>
              )}
              <button className="call-btn my-2" onClick={handleContact}>
                <CallIcon className="me-2 icon" />
                See Contact
              </button>
              <button className="whatsapp-btn" onClick={handleContact}>
                <WhatsAppIcon className="me-2 icon" />
                Whatsapp
              </button>
            </div>
          ) : showDecline ? (
            <div className="border text-center like-container col-12 col-sm-12 col-md-4 col-lg-4">
              <span>
                <CancelIcon className="icon text-danger" />
                <br />
                <small>User has Declined you</small>
              </span>
            </div>
          ) : showAccepted ? (
            <div className="border text-center like-container col-12 col-sm-12 col-md-4 col-lg-4">
              <span>
                <CancelIcon className="icon text-danger" />
                <br />
                <small>Already Accepted</small>
              </span>
            </div>
          ) : (
            <div
              onClick={handleLike}
              style={{ backgroundColor: "#26bd672b" }}
              className="d-none d-lg-block today-match-like-profile-container"
            >
              <span className="text-center d-block m-auto">
                <small>Like this profile? Connect Now</small>
                <CheckCircleIcon className="ms-2 icon" />
              </span>
            </div>
          )}
        </div>
        <div className="today-match-user-details">
          {allUser?.length > 1 && (
            <div className="action-btns">
              <div
                onClick={() => handleChangeUser("prev")}
                className={`prev-user me-3 ${userProfile === 0 && "d-none"}`}
              >
                <span>
                  <ArrowBackIosIcon />
                </span>
                <img
                  src={
                    allUser && allUser[userProfile - 1]?.images.length === 0
                      ? allUser[userProfile - 1]?.gender === "Male"
                        ? IMAGES.male
                        : IMAGES.female
                      : allUser[userProfile - 1]?.images[userProfileImage]
                  }
                  alt=""
                />
              </div>
              <div
                onClick={() => handleChangeUser("next")}
                className={`next-user ${
                  userProfile === allUser?.length - 1 && "d-none"
                }`}
              >
                <img
                  style={{ borderRadius: "100%" }}
                  width="50px"
                  src={
                    allUser && allUser[userProfile + 1]?.images.length === 0
                      ? allUser[userProfile + 1]?.gender === "Male"
                        ? IMAGES.male
                        : IMAGES.female
                      : allUser[userProfile + 1]?.images[userProfileImage]
                  }
                  alt=""
                />
                <span>
                  <ArrowForwardIosIcon />
                </span>
              </div>
            </div>
          )}
          {/* like this profile mobile  */}
          {/* like this profile mobile  */}
          {/* like this profile mobile  */}
          {showContact ? (
            <div className="mt-2 today-match-show-contact-container d-block d-md-block d-lg-none border p-3">
              <span className="text-center text-white">
                <i>
                  <small>You can contact directly</small>
                </i>
              </span>
              <Link to={`tel:${userContact}`}>
                <CallIcon className="icon call-btn" />
              </Link>
              <Link to={`https://wa.me/${userContact}`}>
                <WhatsAppIcon className="icon whatsapp-btn" />
              </Link>
            </div>
          ) : showContactBtn ? (
            <div className="mt-2 border text-center today-match-user-contact-container d-block d-md-block d-lg-none">
              {parseInt(activeUser?.contacts) <= 0 && (
                <span>
                  <small>
                    <Link to="/premium-plans">Upgrade</Link> to Contact
                  </small>
                </span>
              )}
              <button className="call-btn my-2" onClick={handleContact}>
                <CallIcon className="me-2 icon" />
                See Contact
              </button>
              <button className="whatsapp-btn" onClick={handleContact}>
                <WhatsAppIcon className="me-2 icon" />
                Whatsapp
              </button>
            </div>
          ) : (
            <div
              onClick={handleLike}
              style={{ backgroundColor: "#26bd672b" }}
              className="mt-2 d-block d-md-block d-lg-none today-match-like-profile-container"
            >
              <span className="text-center d-block m-auto">
                <small>Like this profile? Connect Now</small>
                <CheckCircleIcon className="ms-2 icon" />
              </span>
            </div>
          )}
          {allUser?.length > 1 && <hr />}
          <div className="profile-card shd">
            <h4>{activeUser?.username}</h4>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="d-flex user-fields-details">
                  <span className="title">
                    <small>Age</small>
                  </span>
                  <span className="ans">
                    : <small>{activeUser?.age || "Not Specified"}</small>
                  </span>{" "}
                  <span className="title">
                    <small>Height</small>
                  </span>
                  <span className="ans">
                    : <small>{activeUser?.height || "Not Specified"}</small>
                  </span>
                  <span className="title">
                    <small>Mother Tongue</small>
                  </span>
                  <span className="ans">
                    : <small>{activeUser?.language || "Not Specified"}</small>
                  </span>
                  <span className="title">
                    <small>Education</small>
                  </span>
                  <span className="ans">
                    :{" "}
                    <small>
                      {activeUser?.qualification || "Not Specified"}
                    </small>
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="d-flex user-fields-details">
                  <span className="title">
                    <small>Marital Status</small>
                  </span>
                  <span className="ans">
                    :{" "}
                    <small>
                      {activeUser?.maritalStatus || "Not Specified"}
                    </small>
                  </span>{" "}
                  <span className="title">
                    <small>State</small>
                  </span>
                  <span className="ans">
                    : <small>{activeUser?.state || "Not Specified"}</small>
                  </span>
                  <span className="title">
                    <small>City</small>
                  </span>
                  <span className="ans">
                    : <small>{activeUser?.city || "Not Specified"}</small>
                  </span>
                  <span className="title">
                    <small>Employed As</small>
                  </span>
                  <span className="ans">
                    : <small>{activeUser?.employedAs || "Not Specified"}</small>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="today-match-tab">
            <button
              className={`${tab === 0 && "active"}`}
              onClick={() => setTab(0)}
            >
              Detailed Profile
            </button>
            <button
              className={`${tab === 1 && "active"}`}
              onClick={() => setTab(1)}
            >
              Partner Preference
            </button>
          </div>

          {tab === 0 ? (
            <div className="shd">
              <div className="my-3 about-myself-container">
                {/* about  */}
                <div className="user-field mb-4">
                  <div className="about-myself-heading">
                    <h6>
                      Personality, Family Details, Career, Partner Expectations
                      etc.
                    </h6>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="user-answer">
                    <small>{activeUser?.about}</small>
                  </div>
                </div>

                {/* Basic info & Lifestyle  */}
                <div className="user-field mb-4">
                  <div className="about-myself-heading">
                    <h6>Basic & Lifestyle</h6>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="user-answer">
                    <div className="user-answer-left">
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Age</small>
                        </span>
                        <span className="ans">
                          : <small>{activeUser?.age || "Not Specified"}</small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Marital Status</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.maritalStatus || "Not Specified"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Height</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>{activeUser?.height || "Not Specified"}</small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Grew up in</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.country || "Not Specified"}
                          </small>
                        </span>
                      </div>
                    </div>
                    <div className="user-answer-right">
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Diet</small>
                        </span>
                        <span className="ans">
                          : <small>{activeUser?.diet || "Not Specified"}</small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Weight</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>{activeUser?.weight || "Not Specified"}</small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Disability</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.disability || "Not Specified"}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Religious Background  */}
                <div className="user-field mb-4">
                  <div className="about-myself-heading">
                    <h6>Reiligious Background</h6>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="user-answer">
                    <div className="user-answer-left">
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Religion</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.religion || "Not Specified"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Community</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.community || "Not Specified"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Sub Community</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.subCommunity || "Not Specified"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Mother Tongue</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.language || "Not Specified"}
                          </small>
                        </span>
                      </div>
                    </div>
                    <div className="user-answer-right">
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Namaaz / Salah</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>{activeUser?.namaaz || "Not Specified"}</small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Zakat</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>{activeUser?.zakat || "Not Specified"}</small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Fasting in Ramadan</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.fasting || "Not Specified"}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Family Background  */}
                <div className="user-field mb-4">
                  <div className="about-myself-heading">
                    <h6>Family Details</h6>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="user-answer">
                    <div className="user-answer-left">
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Father's Status</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.fatherStatus || "Not Specified"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>No. of Brothers</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.brothers || "Not Specified"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>No. of Brothers Married</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.brothersMarried || "Not Specified"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Family Type</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.familyType || "Not Specified"}
                          </small>
                        </span>
                      </div>
                    </div>
                    <div className="user-answer-right">
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Mother's Status</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.motherStatus || "Not Specified"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>No. of Sisters</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.sisters || "Not Specified"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>No. of Sisters Married</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.sistersMarried || "Not Specified"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Native Place</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.nativePlace || "Not Specified"}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Education & Careeer  */}
                <div className="user-field mb-4">
                  <div className="about-myself-heading">
                    <h6>Education & Careeer</h6>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="user-answer">
                    <div className="user-answer-left">
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Highest Qualification</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.qualification || "Not Specified"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Working with</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.workingWith || "Not Specified"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Annual Salary</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>{activeUser?.salary || "Not Specified"}</small>
                        </span>
                      </div>
                    </div>
                    <div className="user-answer-right">
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>College Name</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.collegeName || "Not Specified"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Employed As</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.employedAs || "Not Specified"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Company Name</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.companyName || "Not Specified"}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Location  */}
                <div className="user-field mb-4">
                  <div className="about-myself-heading">
                    <h6>
                      Location of{" "}
                      {activeUser?.gender === "Male" ? "Groom" : "Bride"}
                    </h6>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="user-answer">
                    <div className="user-answer-left" style={{ border: "0" }}>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Country</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.country || "Not Specified"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>State</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>{activeUser?.state || "Not Specified"}</small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>City</small>
                        </span>
                        <span className="ans">
                          : <small>{activeUser?.city || "Not Specified"}</small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="shd">
              <div className="my-3 about-myself-container">
                {/* Basic info & Lifestyle  */}
                <div className="user-field mb-4">
                  <div className="about-myself-heading">
                    <h6>Basic & Lifestyle</h6>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="user-answer">
                    <div className="user-answer-left">
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Age From</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.partnerAgeFrom || "Doesn't Matter"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Age To</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.partnerAgeTo || "Doesn't Matter"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Partner Marital Status</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.partnerMaritalStatus ||
                              "Doesn't Matter"}
                          </small>
                        </span>
                      </div>
                    </div>
                    <div className="user-answer-right">
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Religion</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.partnerReligion || "Doesn't Matter"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Mother Tongue</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.partnerLanguage || "Doesn't Matter"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Diet</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.partnerDiet || "Doesn't Matter"}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Location  */}
                <div className="user-field mb-4">
                  <div className="about-myself-heading">
                    <h6>Location Details</h6>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="user-answer">
                    <div className="user-answer-left" style={{ border: "0" }}>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Country Living In</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.partnerCountry || "Doesn't Matter"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>State</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.partnerState || "Doesn't Matter"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>City</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.partnerCity || "Doesn't Matter"}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Education & Careeer  */}
                <div className="user-field mb-4">
                  <div className="about-myself-heading">
                    <h6>Education & Careeer</h6>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="user-answer">
                    <div className="user-answer-left">
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Partner Education</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.partnerEducation || "Doesn't Matter"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Partner working with</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.partnerWorkingWith || "Doesn't Matter"}
                          </small>
                        </span>
                      </div>
                    </div>
                    <div className="user-answer-right">
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Partner employed as</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.partnerEmployedAs || "Doesn't Matter"}
                          </small>
                        </span>
                      </div>
                      <div className="d-flex user-fields-details">
                        <span className="title">
                          <small>Partner annual salary</small>
                        </span>
                        <span className="ans">
                          :{" "}
                          <small>
                            {activeUser?.partnerSalary || "Doesn't Matter"}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allUser, setAllUser] = useState(null);
  const [userProfile, setUserProfile] = useState(0);
  const [userProfileImage, setUserProfileImage] = useState(0);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  //
  const [showContact, setShowContact] = useState(false);
  const [showContactBtn, setShowContactBtn] = useState(false);
  const [userContact, setUserContact] = useState(false);
  const [upgardePopup, setUpgardePopup] = useState(false);
  const [contactAlert, setContactAlert] = useState(false);
  const [showDecline, setShowDecline] = useState(false);
  const [showAccepted, setShowAccepted] = useState(false);
  // image modal
  const [imageModal, setImageModal] = useState(false);

  const checkDeclineRequest = () => {
    if (allUser !== null) {
      // console.log(activeUser?.deleted);
      const findDecline = activeUser?.deleted?.find(
        (item) => item.msId === allUser[userProfile]?.deleted?.msId
      );
      if (findDecline) {
        setShowDecline(true);
      }
    }
  };

  const checkAcceptedRequest = () => {
    if (allUser !== null) {
      const findAccepted = activeUser?.accepted?.find(
        (item) => item.msId === allUser[userProfile]?.accepted?.msId
      );

      if (findAccepted) {
        setShowAccepted(true);
      }
    }
  };

  const checkLike = () => {
    if (allUser !== null) {
      const findLike = user?.likesData?.find(
        (item) => item.msId === allUser[userProfile]?.msId
      );
      if (findLike) {
        setShowContactBtn(true);
      } else {
        setShowContactBtn(false);
      }
    }
  };

  const checkContactData = () => {
    if (allUser !== null) {
      const findContactData =
        user &&
        user?.contactData.find(
          (item) => item.msId === allUser[userProfile]?.msId
        );
      setUserContact(findContactData?.contactNumber);
      if (findContactData) {
        setShowContact(true);
      } else {
        setShowContact(false);
      }
    }
  };

  const handleContact = () => {
    if (parseInt(user?.contacts) > 0) {
      setContactAlert(true);
    } else {
      setUpgardePopup(true);
    }
  };

  const handleSeeContact = async () => {
    try {
      const res = await axios.post("/api/user/see-contact", {
        userOne: user?.msId,
        userTwo: activeUser?.msId,
      });
      if (res.data.success) {
        message.success(res.data.message);
        dispatch(setUser(res.data.data));
        getUserData();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLike = async () => {
    try {
      const res = await axios.post("/api/user/user-like", {
        userOne: user?.msId,
        userTwo: allUser[userProfile]?.msId,
      });
      if (res.data.success) {
        dispatch(setUser(res.data.data));
        message.success(res.data.message);
        getUserData();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleImage = (direction) => {
    if (direction === "left") {
      if (userProfileImage === 0) {
        setUserProfileImage(0);
      } else {
        setUserProfileImage(userProfileImage - 1);
      }
    } else if (direction === "right") {
      if (userProfileImage === activeUser?.images?.length - 1) {
        setUserProfileImage(activeUser?.images?.length - 1);
      } else {
        setUserProfileImage(userProfileImage + 1);
      }
    }
  };

  const handleChangeUser = (direction) => {
    if (direction === "prev") {
      setUserProfile((prevProfile) => (prevProfile > 0 ? prevProfile - 1 : 0));
    }
    if (direction === "next") {
      setUserProfile((prevProfile) =>
        allUser && prevProfile < allUser.length - 1
          ? prevProfile + 1
          : prevProfile
      );
    }
  };

  const getAllTodayMatchUser = async (gender) => {
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/user/get-all-today-match-users",
        {
          gender: gender === "Male" ? "Female" : "Male",
          partnerAgeFrom: user?.partnerAgeFrom,
          partnerAgeTo: user?.partnerAgeTo,
          partnerCountry: user?.partnerCountry,
          partnerMaritalStatus: user?.partnerMaritalStatus,
          partnerState: user?.partnerState,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setAllUser(res.data.data);
      } else {
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        getAllTodayMatchUser(res.data.data.gender);
        dispatch(setUser(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
    if (user) {
      getAllTodayMatchUser(user?.gender);
    }
  }, []);

  useEffect(() => {
    if (allUser) {
      checkLike();
      checkContactData();
      checkDeclineRequest();
      checkAcceptedRequest();
    }
  }, [allUser, userProfile]);

  const activeUser = allUser && allUser[userProfile];

  return (
    <Layout>
      <div className="today-matches-container container mt-1">
        {/* IMAGE MODAL  */}
        <ImageModal
          imageModal={imageModal}
          setImageModal={setImageModal}
          data={allUser && allUser[userProfile]}
          userImage={userProfileImage}
          setUserImage={setUserProfileImage}
        />
        {/* IMAGE MODAL ENDS  */}
        {user?.isVerified === "No" ? (
          <div
            style={{
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span className="text-center">
              <ErrorIcon className="icon text-danger" />
              <br />
              <b>
                Your Profile is under Review for Admin Approval and it's take
                upto 24 hours
              </b>
              <br />
              <b>You'll be notified through Email once Approved!</b>
            </span>
          </div>
        ) : !allUser ? (
          <div
            style={{
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            No User Found. Get back after some time!
          </div>
        ) : loading ? (
          <div
            className="spinner-grow text-success text-center mt-4"
            role="status"
          >
            <span className="text-center sr-only d-block m-auto"></span>
          </div>
        ) : (
          <TodayMatchContainer />
        )}
      </div>
      {/* Contact Upgrade Modal */}
      <div
        onClick={() => setUpgardePopup(!upgardePopup)}
        className={`contact-upgrade-modal ${upgardePopup && "active"}`}
      >
        <div className="upgrade-container">
          <span>
            <small>To Contact directly Upgrade Now</small>
          </span>
          <button onClick={() => navigate("/premium-plans")}>
            Upgrade Now
          </button>
        </div>
      </div>
      <div
        onClick={() => setContactAlert(!contactAlert)}
        className={`contact-upgrade-modal ${contactAlert && "active"}`}
      >
        <div className="upgrade-container">
          <span className="text-center">
            <small>Are you sure you want to see the Contact?</small>
          </span>
          <div className="d-flex">
            <button className="px-4" onClick={handleSeeContact}>
              Yes
            </button>
            <button
              className="px-4"
              onClick={() => setContactAlert(!contactAlert)}
            >
              No
            </button>
          </div>
          <span className="text-center">
            <small>
              After clicking on yes remaining Contacts will be{" "}
              {user?.contacts > 0 ? parseInt(user?.contacts) - 1 : 0}
            </small>
          </span>
        </div>
      </div>
    </Layout>
  );
};

export default TodayMatches;
