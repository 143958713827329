import React, { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const EducationCareer = ({ handleChange, activeUser, setActiveUser }) => {
  const [field, setField] = useState(true);
  return (
    <div className="basic-info mt-3">
      <div className="title">
        <div className="d-flex justify-content-between">
          <span>Education & Career</span>
          <span>
            {field ? (
              <RemoveIcon onClick={() => setField(false)} />
            ) : (
              <AddIcon onClick={() => setField(true)} />
            )}
          </span>
        </div>
        <hr className="m-0 p-0 mt-2 mb-3" />
        <div className={`row fields-container ${field ? "" : "off"}`}>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Your Highest Qualifcation*
              </label>
              <select
                onChange={(e) => handleChange("qualification", e.target.value)}
                value={activeUser?.qualification}
                className="form-select"
                name="qualification"
              >
                <option value="Select">Select</option>
                <option value="SSC/10th">SSC/10th</option>
                <option value="Intermediate/12th">Intermediate/12th</option>
                <option value="Degree/Graduate">Degree/Graduate</option>
                <option value="Post Graduation">Post Graduation</option>
                <option value="Phd/Doctorate">Phd/Doctorate</option>
                <option value="Hafiz e Qur'an/Qari">Hafiz e Qur'an/Qari</option>
                <option value="Aalim-E-Deen">Aalim-E-Deen</option>
                <option value="Mufti">Mufti</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          {activeUser?.qualification === "Other" && (
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="">
                  Your Qualification
                </label>
                <input
                  placeholder="Enter your qualification"
                  className="form-control"
                  type="text"
                  name="collegeName"
                  onChange={(e) => handleChange("collegeName", e.target.value)}
                  value={activeUser?.collegeName}
                />
              </div>
            </div>
          )}
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                You work{" "}
                {activeUser?.workingWith === "Business/Self Employed"
                  ? "as"
                  : "with"}
                *
              </label>
              <select
                onChange={(e) => handleChange("workingWith", e.target.value)}
                value={activeUser?.workingWith}
                className="form-select"
                name="workingWith"
              >
                <option value="Select">Select</option>
                <option value="Private Sector">Private Sector</option>
                <option value="Government Sector">Government Sector</option>
                <option value="Defense/Civil Services">
                  Defense / Civil Services
                </option>
                <option value="Business/Self Employed">
                  Business / Self Employed
                </option>
                <option value="Not Working">Not Working</option>
              </select>
            </div>
          </div>
          {activeUser?.workingWith !== "Not Working" && (
            <>
              {activeUser?.workingWith === "Business/Self Employed" ? (
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="mb-3 form-fields">
                    <label className="form-label" htmlFor="">
                      Enter Your Business*
                    </label>
                    <input
                      type="text"
                      name="business"
                      placeholder="Enter your business"
                      className="form-control"
                      value={activeUser?.business}
                      onChange={(e) => handleChange("business", e.target.value)}
                    />
                  </div>
                </div>
              ) : (
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="mb-3 form-fields">
                    <label className="form-label" htmlFor="">
                      Employee As*
                    </label>
                    <select
                      onChange={(e) =>
                        handleChange("employedAs", e.target.value)
                      }
                      value={activeUser?.employedAs}
                      className="form-select"
                      name="employedAs"
                    >
                      <option value="Select">Select</option>
                      <option value="Banking Professional">
                        Banking Professional
                      </option>
                      <option value="Chartered Accountant">
                        Chartered Accountant
                      </option>
                      <option value="Company Secretary">
                        Company Secretary
                      </option>
                      <option value="Finance Professional">
                        Finance Professional
                      </option>
                      <option value="Event Manager">Event Manager</option>
                      <option value="Air Hostess">Air Hostess</option>
                      <option value="Pilot">Pilot</option>
                      <option value="Architect">Architect</option>
                      <option value="Interior Designer">
                        Interior Designer
                      </option>
                      <option value="Web/UX Designer">Web/UX Designer</option>
                      <option value="Artist">Artist</option>
                      <option value="Beautician">Beautician</option>
                      <option value="Fashion Designer">Fashion Designer</option>
                      <option value="Hair Stylist">Hair Stylist</option>
                      <option value="IAS/IRS/IES/IFS">IAS/IRS/IES/IFS</option>
                      <option value="Army/Navy">Army/Navy</option>
                      <option value="Lecturer">Lecturer</option>
                      <option value="Lawyer">Lawyer</option>
                      <option value="Teacher">Teacher</option>
                      <option value="Civil Engineer">Civil Engineer</option>
                      <option value="IT Profession">IT Profession</option>
                      <option value="Software Developer">
                        Software Developer
                      </option>
                      <option value="Doctor">Doctor</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
              )}
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Annual{" "}
                    {activeUser?.workingWith === "Business/Self Employed"
                      ? "Turnover"
                      : "Salary"}
                  </label>
                  <select
                    onChange={(e) => handleChange("salary", e.target.value)}
                    value={activeUser?.salary}
                    className="form-select"
                    name="salary"
                  >
                    <option value="Select">Select</option>
                    <option value="Upto 1 Lakh">Upto 1 Lakh</option>
                    <option value="1 to 2 Lakh">1 to 2 Lakh</option>
                    <option value="2 to 4 Lakh">2 to 4 Lakh</option>
                    <option value="4 to 7 Lakh">4 to 7 Lakh</option>
                    <option value="7 to 10 Lakh">7 to 10 Lakh</option>
                    <option value="10 to 15 Lakh">10 to 15 Lakh</option>
                    <option value="15 to 20 Lakh">15 to 20 Lakh</option>
                    <option value="20 to 40 Lakh">20 to 40 Lakh</option>
                    <option value="40 to 80 Lakh">40 to 80 Lakh</option>
                    <option value="1 Crore">1 Crore</option>
                    <option value="More than 1 Crore">More than 1 Crore</option>
                    <option value="Disclose it later">Disclose it later</option>
                  </select>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EducationCareer;
