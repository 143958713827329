import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupIcon from "@mui/icons-material/Group";
import PaymentIcon from "@mui/icons-material/Payment";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";

const AdminSidemenu = ({ menu, setMenu }) => {
  return (
    <div className={`admin-sidemenu-container ${menu && "active"}`}>
      <ul>
        <li onClick={() => setMenu(!menu)}>
          <Link to="/admin-dashboard">
            <HomeIcon className="me-2" />
            Home
          </Link>
        </li>
        <li onClick={() => setMenu(!menu)}>
          <Link>
            <AccountCircleIcon className="me-2" />
            Profile
          </Link>
        </li>
        <li onClick={() => setMenu(!menu)}>
          <Link to="/admin-users">
            <GroupIcon className="me-2" />
            Users
          </Link>
        </li>
        <li onClick={() => setMenu(!menu)}>
          <Link to="/admin-payments">
            <PaymentIcon className="me-2" />
            Payments
          </Link>
        </li>
      </ul>
      <CancelIcon
        onClick={() => setMenu(!menu)}
        className="text-dark cancel-icon"
      />
    </div>
  );
};

export default AdminSidemenu;
