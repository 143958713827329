import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import "./AdminPayments.css";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import axios from "axios";
import { message } from "antd";

const AdminPayments = () => {
  const [search, setSearch] = useState("");
  const [payments, setPayments] = useState([]);
  const [paymentsData, setPaymentsData] = useState([]);
  const [todayRevenue, setTodayRevenue] = useState(null);
  const [monthRevenue, setMonthRevenue] = useState(null);
  const [lastMonthRevenue, setLastMonthRevenue] = useState(null);
  const [yearRevenue, setYearRevenue] = useState(null);

  async function getAllPayments() {
    try {
      const res = await axios.get("/api/admin/get-all-payments", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setPayments(res.data.data.reverse());
        setPaymentsData(res.data.data.reverse());
        setTodayRevenue(res.data.todayRevenue);
        setMonthRevenue(res.data.thisMonthRevenue);
        setLastMonthRevenue(res.data.lastMonthRevenue);
        setYearRevenue(res.data.thisYearRevenue);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleFilter() {
    if (search === "") {
      setPayments(paymentsData);
    } else {
      const filterUsers = paymentsData?.filter((item) =>
        item?.email.toLowerCase().includes(search.toLowerCase())
      );
      setPayments(filterUsers);
    }
  }

  useEffect(() => {
    handleFilter();
  }, [search]);

  useEffect(() => {
    getAllPayments();
  }, []);

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Payments</h3>
      </div>
      <hr />
      {/* PAYMENT STATS  */}
      <div className="admin-payment-container">
        <div className="admin-dashboard-card">
          <div className="details">
            <h1>
              <b>{todayRevenue && todayRevenue}</b>
            </h1>
            <span>Today's Revenue</span>
          </div>
          <div className="icon-container">
            <SupportAgentIcon className="icon" />
          </div>
        </div>
        <div className="admin-dashboard-card">
          <div className="details">
            <h1>
              <b>{monthRevenue && monthRevenue}</b>
            </h1>
            <span>Monthly Revenue</span>
          </div>
          <div className="icon-container">
            <SupportAgentIcon className="icon" />
          </div>
        </div>
        <div className="admin-dashboard-card">
          <div className="details">
            <h1>
              <b>{lastMonthRevenue && lastMonthRevenue}</b>
            </h1>
            <span>Last Month Revenue</span>
          </div>
          <div className="icon-container">
            <SupportAgentIcon className="icon" />
          </div>
        </div>
        <div className="admin-dashboard-card">
          <div className="details">
            <h1>
              <b>{yearRevenue && yearRevenue}</b>
            </h1>
            <span>Yearly Revenue</span>
          </div>
          <div className="icon-container">
            <SupportAgentIcon className="icon" />
          </div>
        </div>
      </div>
      {/* LIST  */}
      <div className="payment-history-container">
        <div className="tools">
          <input
            type="text"
            placeholder="Enter email"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <table className="table user-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Plan</th>
              <th>Validity</th>
              <th>Pay Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {payments &&
              payments?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.number}</td>
                    <td>{item.plan}</td>
                    <td>{item.premiumValidityMonths} Months</td>
                    <td>
                      {new Date(item.payDate).toLocaleString("default", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </td>
                    <td>{item.status}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </AdminLayout>
  );
};

export default AdminPayments;
