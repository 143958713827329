import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { message } from "antd";
import { setUser } from "../../../redux/features/userSlice";
import axios from "axios";
import MobileVerify from "../MobileVerify/MobileVerify";
import "./MyContactDetails.css";

const MyContactDetails = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [editContact, setEditContact] = useState(false);
  const [ph, setPh] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/update-user", {
        email: user?.email,
        mobile: ph,
      });
      if (res.data.success) {
        message.success(res.data.message);
        dispatch(setUser(res.data.data));
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
      <div className="contact-container">
        <div className="contact-title">Want to change your contact number?</div>
        <button className="request-btn">Send a request</button>
        <div className="notes">
          <span>
            <small>
              Note: Your number is safe with us. Only Premium members has the
              accesss to see your contact details
            </small>
          </span>
        </div>
      </div>
    </Layout>
  );
};

export default MyContactDetails;
