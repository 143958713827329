import React, { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./SideMenu.css";
import { message } from "antd";
import IMAGES from "../../img/image";

const SideMenu = ({ sideMenu, setSideMenu }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [submenu, setSubmenu] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    message.success("Logout Successful");
    navigate("/login");
  };
  return (
    <div
      className={`sidemenu-container d-block d-md-block d-lg-none ${
        sideMenu ? "active" : ""
      }`}
    >
      <div className="sidemenu">
        <HighlightOffIcon
          onClick={() => setSideMenu(!sideMenu)}
          className="close-icon"
        />
        {user && (
          <img
            className="sidemenu-img"
            src={
              user?.images?.length > 0
                ? user?.images[0]
                : user?.gender === "Male"
                ? IMAGES.male
                : IMAGES.female
            }
            alt=""
          />
        )}
        <ul className="p-0">
          {/* Logged In  */}
          {user && (
            <li>
              <Link to="/user-dashboard">Dashboard</Link>
            </li>
          )}
          {user && (
            <li>
              <Link to="/premium-plans">Premium</Link>
            </li>
          )}
          {user && (
            <li>
              <Link to="/today-matches">Matches</Link>
            </li>
          )}
          {user && (
            <li>
              <Link to="/search">Search</Link>
            </li>
          )}
          {user && (
            <li>
              <Link to="/inbox-received">Inbox</Link>
            </li>
          )}
          {!user && (
            <li>
              <Link to="/">Home</Link>
            </li>
          )}
          {!user && (
            <li>
              <Link to="/about">About</Link>
            </li>
          )}
          {!user && (
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          )}
          {!user && (
            <div className="sidemenu-action-btn">
              <button
                onClick={() => {
                  navigate("/login");
                  setSideMenu(!sideMenu);
                }}
                className="request-btn"
              >
                Login
              </button>
              <button
                onClick={() => {
                  navigate("/register");
                  setSideMenu(!sideMenu);
                }}
                className="request-btn"
              >
                Register
              </button>
            </div>
          )}
          {user && (
            <>
              <div className="sidemenu-social-icons">
                <Link
                  target="_blank"
                  to="https://www.instagram.com/halalrishtey/"
                >
                  <InstagramIcon className="icon" />
                </Link>
                <Link target="_blank" to="mailto:support@halalrishtey.com">
                  <EmailIcon className="icon" />
                </Link>
                <Link target="_blank" to="https://wa.me/918125751585">
                  <WhatsAppIcon className="icon" />
                </Link>
              </div>
              <hr />
            </>
          )}
          {user && (
            <div className="logout" onClick={handleLogout}>
              Logout
              <LogoutIcon className="icon" />
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
