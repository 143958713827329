import React from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const AboutMyself = ({ data }) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="my-3 about-myself-container">
      {/* Basic info & Lifestyle  */}
      <div className="user-field mb-4">
        <div className="about-myself-heading">
          <h6>Basic & Lifestyle</h6>
          {!params.id && location.pathname !== "/profile-pdf" && (
            <span onClick={() => navigate("/edit-profile")}>
              Edit
              <PlayArrowIcon className="icon" />
            </span>
          )}
        </div>
        <hr className="m-0 mb-2" />
        <div className="user-answer">
          <div className="user-answer-left">
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Age</small>
              </span>
              <span className="ans">
                : <small>{data?.age || "Not Specified"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Marital Status</small>
              </span>
              <span className="ans">
                : <small>{data?.maritalStatus || "Not Specified"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Height</small>
              </span>
              <span className="ans">
                : <small>{data?.height || "Not Specified"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Complexion</small>
              </span>
              <span className="ans">
                : <small>{data?.complexion || "Not Specified"}</small>
              </span>
            </div>
          </div>
          <div className="user-answer-right">
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Interest/Hobbies</small>
              </span>
              <span className="ans">
                : <small>{data?.diet || "Not Specified"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Body Type</small>
              </span>
              <span className="ans">
                : <small>{data?.bodyType || "Not Specified"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Weight</small>
              </span>
              <span className="ans">
                : <small>{data?.weight || "Not Specified"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Disability</small>
              </span>
              <span className="ans">
                : <small>{data?.disability || "Not Specified"}</small>
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Religious Background  */}
      <div className="user-field mb-4">
        <div className="about-myself-heading">
          <h6>Religious Background</h6>
          {!params.id && location.pathname !== "/profile-pdf" && (
            <span onClick={() => navigate("/edit-profile")}>
              Edit
              <PlayArrowIcon className="icon" />
            </span>
          )}
        </div>
        <hr className="m-0 mb-2" />
        <div className="user-answer">
          <div className="user-answer-left">
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Religion</small>
              </span>
              <span className="ans">
                : <small>{data?.religion || "Not Specified"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Community</small>
              </span>
              <span className="ans">
                : <small>{data?.community || "Not Specified"}</small>
              </span>
              <span className="title">
                <small>Namaaz / Salah</small>
              </span>
              <span className="ans">
                : <small>{data?.namaaz || "Not Specified"}</small>
              </span>
            </div>
          </div>
          <div className="user-answer-right">
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Maslak</small>
              </span>
              <span className="ans">
                : <small>{data?.maslak || "Not Specified"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Mother Tongue</small>
              </span>
              <span className="ans">
                : <small>{data?.language || "Not Specified"}</small>
              </span>
            </div>
            {/* <div className="d-flex user-fields-details">
              <span className="title">
                <small>Zakat</small>
              </span>
              <span className="ans">
                : <small>{data?.zakat || "Not Specified"}</small>
              </span>
            </div> */}
            {/* <div className="d-flex user-fields-details">
              <span className="title">
                <small>Fasting in Ramadan</small>
              </span>
              <span className="ans">
                : <small>{data?.fasting || "Not Specified"}</small>
              </span>
            </div> */}
          </div>
        </div>
      </div>

      {/* Family Background  */}
      <div className="user-field mb-4">
        <div className="about-myself-heading">
          <h6>Family Details</h6>
          {!params.id && location.pathname !== "/profile-pdf" && (
            <span onClick={() => navigate("/edit-profile")}>
              Edit
              <PlayArrowIcon className="icon" />
            </span>
          )}
        </div>
        <hr className="m-0 mb-2" />
        <div className="user-answer">
          <div className="user-answer-left">
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Father's Status</small>
              </span>
              <span className="ans">
                : <small>{data?.fatherStatus || "Not Specified"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>No. of Brothers</small>
              </span>
              <span className="ans">
                : <small>{data?.brothers || "Not Specified"}</small>
              </span>
            </div>
            {/* <div className="d-flex user-fields-details">
              <span className="title">
                <small>No. of Brothers Married</small>
              </span>
              <span className="ans">
                : <small>{data?.brothersMarried || "Not Specified"}</small>
              </span>
            </div> */}
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Family Type</small>
              </span>
              <span className="ans">
                : <small>{data?.familyType || "Not Specified"}</small>
              </span>
            </div>
            {data?.maritalStatus !== "Single (Never Married)" && (
              <div className="d-flex user-fields-details">
                <span className="title">
                  <small>Childrens</small>
                </span>
                <span className="ans">
                  : <small>{data?.childrens || "Not Specified"}</small>
                </span>
              </div>
            )}
          </div>
          <div className="user-answer-right">
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Mother's Status</small>
              </span>
              <span className="ans">
                : <small>{data?.motherStatus || "Not Specified"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>No. of Sisters</small>
              </span>
              <span className="ans">
                : <small>{data?.sisters || "Not Specified"}</small>
              </span>
            </div>
            {/* <div className="d-flex user-fields-details">
              <span className="title">
                <small>No. of Sisters Married</small>
              </span>
              <span className="ans">
                : <small>{data?.sistersMarried || "Not Specified"}</small>
              </span>
            </div> */}
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Native Place</small>
              </span>
              <span className="ans">
                : <small>{data?.nativePlace || "Not Specified"}</small>
              </span>
            </div>
            {data?.maritalStatus !== "Single (Never Married)" &&
              data?.chilrens !== "yes" && (
                <div className="d-flex user-fields-details">
                  <span className="title">
                    <small>No. of Childrens</small>
                  </span>
                  <span className="ans">
                    : <small>{data?.childrensCount || "Not Specified"}</small>
                  </span>
                </div>
              )}
          </div>
        </div>
      </div>

      {/* Education & Careeer  */}
      <div className="user-field mb-4">
        <div className="about-myself-heading">
          <h6>Education & Career</h6>
          {!params.id && location.pathname !== "/profile-pdf" && (
            <span onClick={() => navigate("/edit-profile")}>
              Edit
              <PlayArrowIcon className="icon" />
            </span>
          )}
        </div>
        <hr className="m-0 mb-2" />
        <div className="user-answer">
          <div className="user-answer-left">
            <div className="d-flex user-fields-details">
              {data?.qualification === "Other" ? (
                <>
                  <span className="title">
                    <small>Highest Qualification</small>
                  </span>
                  <span className="ans">
                    : <small>{data?.collegeName || "Not Specified"}</small>
                  </span>
                </>
              ) : (
                <>
                  <span className="title">
                    <small>Highest Qualification</small>
                  </span>
                  <span className="ans">
                    : <small>{data?.qualification || "Not Specified"}</small>
                  </span>
                </>
              )}
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>
                  Working{" "}
                  {data?.workingWith === "Business/Self Employed"
                    ? "As"
                    : "With"}
                </small>
              </span>
              <span className="ans">
                : <small>{data?.workingWith || "Not Specified"}</small>
              </span>
            </div>
          </div>
          <div className="user-answer-right">
            {data?.workingWith === "Business/Self Employed" && (
              <div className="d-flex user-fields-details">
                <span className="title">
                  <small>Business</small>
                </span>
                <span className="ans">
                  : <small>{data?.business || "Not Specified"}</small>
                </span>
              </div>
            )}
            {data?.workingWith !== "Business/Self Employed" && (
              <div className="d-flex user-fields-details">
                <span className="title">
                  <small>Employed As</small>
                </span>
                <span className="ans">
                  : <small>{data?.employedAs || "Not Specified"}</small>
                </span>
              </div>
            )}
            {data?.workingWith !== "Not Working" && (
              <div className="d-flex user-fields-details">
                <span className="title">
                  <small>Annual Salary</small>
                </span>
                <span className="ans">
                  : <small>{data?.salary || "Not Specified"}</small>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Location  */}
      <div className="user-field mb-4">
        <div className="about-myself-heading">
          <h6>Location of {data?.gender === "Male" ? "Groom" : "Bride"}</h6>
          {!params.id && location.pathname !== "/profile-pdf" && (
            <span onClick={() => navigate("/edit-profile")}>
              Edit
              <PlayArrowIcon className="icon" />
            </span>
          )}
        </div>
        <hr className="m-0 mb-2" />
        <div className="user-answer">
          <div className="user-answer-left" style={{ border: "0" }}>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>Country</small>
              </span>
              <span className="ans">
                : <small>{data?.country || "Not Specified"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>State</small>
              </span>
              <span className="ans">
                : <small>{data?.state || "Not Specified"}</small>
              </span>
            </div>
            <div className="d-flex user-fields-details">
              <span className="title">
                <small>City</small>
              </span>
              <span className="ans">
                : <small>{data?.city || "Not Specified"}</small>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* about  */}
      <div className="user-field mb-4">
        <div className="about-myself-heading">
          <h6>About Me & Partner Expectations</h6>
          {!params.id && location.pathname !== "/profile-pdf" && (
            <span onClick={() => navigate("/edit-profile")}>
              Edit
              <PlayArrowIcon className="icon" />
            </span>
          )}
        </div>
        <hr className="m-0 mb-2" />
        <div className="user-answer">
          <small>
            {data?.about === "" || data?.about === null
              ? "Not Specified"
              : data?.about}
          </small>
        </div>
      </div>
    </div>
  );
};

export default AboutMyself;
