import React, { useEffect, useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Country, State, City } from "country-state-city";

const Location = ({ handleChange, activeUser, setActiveUser }) => {
  let countryData = Country.getAllCountries();
  const [state, setState] = useState(null);
  const [cities, setCities] = useState(null);
  const [countrySelect, setCountrySelect] = useState(false);
  const [stateSelect, setStateSelect] = useState(false);
  const [citySelect, setCitySelect] = useState(false);
  const [field, setField] = useState(true);

  useEffect(() => {
    const selectedCountry = countryData.filter(
      (item) => item.name === activeUser?.country
    );
    const stateData = State.getAllStates().filter(
      (item) => item?.countryCode === selectedCountry[0]?.isoCode
    );
    setState(stateData);
  }, [activeUser?.country]);

  // get city
  useEffect(() => {
    const selectedState = State.getAllStates().filter(
      (item) => item.name === activeUser?.state
    );
    const cityData = City.getAllCities().filter(
      (item) => item.stateCode === selectedState[0]?.isoCode
    );
    setCities(cityData);
  }, [activeUser?.state]);

  useEffect(() => {
    if (activeUser?.country !== "") {
      if (
        Country.getAllCountries().find(
          (item) => item.name === activeUser?.country
        )
      ) {
        setCountrySelect(false);
      } else {
        setCountrySelect(true);
      }
    }
    if (activeUser?.state !== "") {
      if (state?.find((item) => item.name === activeUser?.state)) {
        setStateSelect(false);
      } else {
        setStateSelect(true);
      }
    }
    if (activeUser?.city !== "") {
      if (cities?.find((item) => item.name === activeUser?.city)) {
        setCitySelect(false);
      } else {
        setCitySelect(true);
      }
    }
  }, [activeUser?.country, activeUser?.state, activeUser?.city]);
  return (
    <div className="basic-info mt-3">
      <div className="title">
        <div className="d-flex justify-content-between">
          <span>Location</span>
          <span>
            {field ? (
              <RemoveIcon onClick={() => setField(false)} />
            ) : (
              <AddIcon onClick={() => setField(true)} />
            )}
          </span>
        </div>
        <hr className="m-0 p-0 mt-2 mb-3" />
        <div className={`row fields-container ${field ? "" : "off"}`}>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Country*
              </label>
              <input
                className="form-control"
                list="country"
                type="text"
                name="country"
                onChange={(e) => handleChange("country", e.target.value)}
                value={activeUser?.country}
              />
              <datalist name="country" id="country">
                {countryData?.map((item) => {
                  return <option value={item.name}>{item.name}</option>;
                })}
              </datalist>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                State*
              </label>
              <input
                className="form-control"
                list="state"
                type="text"
                name="state"
                onChange={(e) => handleChange("state", e.target.value)}
                value={activeUser?.state}
              />
              <datalist name="state" id="state">
                {state?.map((item, i) => {
                  return (
                    <option key={i} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </datalist>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                City*
              </label>
              <input
                className="form-control"
                list="city"
                type="text"
                name="city"
                onChange={(e) => handleChange("city", e.target.value)}
                value={activeUser?.city}
              />
              <datalist name="city" id="city">
                {cities?.map((item, i) => {
                  return (
                    <option key={i} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </datalist>
            </div>
          </div>
          {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Pincode*
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter your pincode"
                name="pincode"
                    onChange={(e) => handleChange("salary", e.target.value)}
                
                value={activeUser?.pincode}
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Location;
